import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { loginToken } from "../../redux/Token";
import { nuevoContacto } from "../../redux/contacto";
import logo from "../../image/logo-humanware-blanco.png";
import CustomTextField from "../../components/CustomTextField";
import { Paper } from "@mui/material";
import emailjs from '@emailjs/browser';
import { toast } from "react-toastify";

const validacionSchema = yup.object().shape({
  nombre: yup.string().required("El nombre es requerido"),
  apellido: yup.string().required("El apellido es requerido"),
  mail: yup.string().email("El correo electrónico no es válido").required("El correo electrónico es requerido"),
});

const FormularioContacto = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((store) => store.token.token);
  const loadingNuevoContacto = useSelector((store) => store.contacto.loadingNuevoContacto);
  const [Token, setToken] = useState("");

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: {
      nombre: "",
      apellido: "",
      puesto: "",
      mail: "",
      telefono: "",
      empresa: "",
      productoInteres: "Capacitaciones",
      contactoDesde: "",
      comentarios: "",
      referidoDesde: "",
    },
    resolver: yupResolver(validacionSchema),
    mode: "onChange",
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    let refreshInterval;

    const refreshToken = () => {
      dispatch(loginToken());
    };

    const startRefreshTimer = () => {
      refreshInterval = setInterval(refreshToken, 1800000); // Llamada cada 30 minutos
    };

    dispatch(loginToken());
    startRefreshTimer();

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  useEffect(() => {
    if (token?.length > 0) {
      setToken(token);
    }
  }, [token]);

  const sendEmail = (data) => {
    
    emailjs
      .send('service_gb7bwvk', 'template_ykud1qq', data, 'Qn7axufRRDYYKqyhD')
      .then(
        () => {
          toast.success('Formulario enviado con éxito!', {
            theme: "dark",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          reset();
        },
        (error) => {
          toast.error('Error al enviar el formulario. Inténtalo de nuevo.', {
            theme: "dark",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          console.log('Error...', error.text);
        }
      );
  };

  // const enviarFormulario = async (data) => {
  //   try {
  //     const id = await dispatch(nuevoContacto(Token, data));
  //     reset({
  //       nombre: "",
  //       apellido: "",
  //       puesto: "",
  //       mail: "",
  //       telefono: "",
  //       empresa: "",
  //       productoInteres: "Capacitaciones",
  //       contactoDesde: "",
  //       comentarios: "",
  //     });
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };


  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          container
          sx={{
            backgroundColor: "#081526",
            width: "100%",
            p: 4,
            mt: { xs: "1rem" },
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
        >
          <Grid item xs={12}>
            <Box
              component="img"
              src={logo}
              alt="Logo de la empresa"
              sx={{
                width: { xs: "120px", md: "200px" },
                height: "auto",
                float: "left",
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography
              component="h1"
              variant="h4"
              sx={{
                mt: 3,
                fontSize: {
                  xs: "1rem",
                  md: "1.5rem",
                  fontWeight: 600,
                  color: "#fff",
                },
              }}
            >
              Formulario de Contacto
            </Typography>
          </Grid>
        </Grid>

        <Paper
          elevation={3}
          sx={{
            p: 2,
            mb: 2,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          }}
        >
          <FormProvider {...methods}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  Component={TextField}
                  req="true"
                  type="text"
                  label="Nombre"
                  name="nombre"
                  helperText={"El nombre es requerido"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  Component={TextField}
                  req="true"
                  type="text"
                  label="Apellidos"
                  name="apellido"
                  helperText={"El apellido es requerido"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  Component={TextField}
                  req="true"
                  type="email"
                  label="Mail"
                  name="mail"
                  helperText={"El mail es requerido"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField Component={TextField} type="text" label="Telefono" name="telefono" />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField Component={TextField} type="text" label="Empresa" name="empresa" />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField Component={TextField} type="text" label="Puesto" name="puesto" />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  Component={TextField}
                  type="text"
                  label="Producto de Interés"
                  name="productoInteres"
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField Component={TextField} type="text" label="Referido desde" name="referidoDesde" />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField Component={TextField} type="text" label="Contacto desde" name="contactoDesde" />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  Component={TextField}
                  type="text"
                  label="Comentarios"
                  name="comentarios"
                  multiline={true}
                  rows="4"
                />
              </Grid>
            </Grid>
          </FormProvider>
          <Box sx={{ mt: 3, mb: 2, position: "relative" }}>
            <Button
              type="button"
              onClick={handleSubmit(sendEmail)}
              variant="contained"
              disabled={loadingNuevoContacto === "LOADING"}
              sx={{ backgroundColor: "#1786A0", color: "#fff" }}
            >
              Enviar
              {loadingNuevoContacto === "LOADING" && (
                <CircularProgress
                  size={27}
                  sx={{
                    color: "#fff",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default FormularioContacto;
