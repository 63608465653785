import axios from "axios";
import { toast } from "react-toastify";

let loadingToast = null;

const ToastLoading = (msj) => {
  loadingToast = toast.info(msj, {
    theme: "dark",
    position: "top-center",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};

const ToastSuccess = (msj) => {
  toast.success(msj, {
    theme: "dark",
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onOpen: () => {
      if (loadingToast !== null) {
        toast.dismiss(loadingToast);
      }
    },
  });
};

const ToastError = (msj) => {
  toast.error(msj, {
    theme: "dark",
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onOpen: () => {
      if (loadingToast !== null) {
        toast.dismiss(loadingToast);
      }
    },
  });
};

const dataInicial = {
  loadingNuevoContacto: "",
  resultadoNuevoContacto: "",
};

const NUEVO_CONTACTO_LOADING = "NUEVO_CONTACTO_LOADING";
const NUEVO_CONTACTO_EXITO = "NUEVO_CONTACTO_EXITO";
const NUEVO_CONTACTO_ERROR = "NUEVO_CONTACTO_ERROR";

export default function contactosReducer(state = dataInicial, action) {
  switch (action.type) {
    case NUEVO_CONTACTO_LOADING:
      return {
        ...state,
        loadingNuevoContacto: action.resultado,
      };
    case NUEVO_CONTACTO_EXITO:
      return {
        ...state,
        loadingNuevoContacto: action.resultado,
        resultadoNuevoContacto: action.payload,
      };
    case NUEVO_CONTACTO_ERROR:
      return {
        ...dataInicial,
        loadingNuevoContacto: action.resultado,
      };
    default:
      return { ...state };
  }
}

export const nuevoContacto = (token, datos) => async (dispatch) => {
  dispatch({
    type: NUEVO_CONTACTO_LOADING,
    resultado: "LOADING",
  });
  ToastLoading("Procesando...");
  return new Promise((resolve, reject) => {
    if (Object.keys(datos).length > 0 && token) {
      axios
        .post(
          `https://hw365api.azurewebsites.net/api/hrfactors/clientePotencial`,
          {
            firstname: datos?.nombre,
            lastname: datos?.apellido,
            jobtitle: datos?.puesto,
            emailaddress1: datos?.mail,
            telephone1: datos?.telefono,
            empresa: datos?.empresa,
            productoDeInteres: datos?.productoInteres,
            contactoDesde: datos?.contactoDesde,
            description: datos?.comentarios,
            new_referidodesde: datos?.referidoDesde,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: NUEVO_CONTACTO_EXITO,
            resultado: "EXITO",
            payload: response.data,
          });

          ToastSuccess("Formulario cargado con éxito");
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);

          dispatch({
            type: NUEVO_CONTACTO_ERROR,
            resultado: "ERROR",
          });

          ToastError(error.response.data);
          reject(error);
        });
    }
  });
};