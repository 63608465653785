import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Container,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Contacto.module.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import Formulario from "../../components/Formulario";
import { Helmet } from "react-helmet";

function Contacto() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Contacto - Humanware Applications</title>
        <meta
          name="description"
          content="En Humanware Applications proveemos soluciones corporativas para la
          transformación digital de los Procesos."
        />
        <meta
          name="keywords"
          content="humanware, humanware applications, contacto humanware, , contacto humanware applications,"
        ></meta>
      </Helmet>
      <Container sx={{ mt: 0 }} className={`${styles.fondoContactoPortada}`}>
        <Typography
          variant="h6"
          color="primary"
          sx={{ pt: 35, pl: 15 }}
          className={`${styles.parrafo01}`}
        >
          <Link to="/" className={`${styles.link}`}>
            Home
            <KeyboardArrowRightIcon
              color="primary"
              className={`${styles.link}`}
            />
          </Link>
          Contacto
        </Typography>
        <Typography
          className={`${styles.parrafo02}`}
          variant="h4"
          color="primary"
          sx={{ pt: 2, pl: 15, fontWeight: 600 }}
        >
          Acá Empieza el Crecimiento.
        </Typography>
      </Container>
      <Grid
        className={`${styles.contactoContainer}`}
        container
        spacing={2}
        columns={16}
        sx={{
          m: 1,
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Grid item xs={7} className={`${styles.contactoIframe}`}>
          <iframe
            className={`${styles.iframe}`}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.0531627081596!2d-60.669051081437196!3d-32.92319362594412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b654ae6ae9e987%3A0x76698773bf876c1b!2sAv.%20Carballo%20186%2C%20Rosario%2C%20Santa%20Fe!5e0!3m2!1ses!2sar!4v1663099092834!5m2!1ses!2sar"
            width="600"
            height="450"
            style={{ border: "1px solid #9CC0F9" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
        <Grid item xs={7} className={`${styles.contactoFormulario}`}>
          <Formulario />
        </Grid>
      </Grid>
      <Grid
        className={`${styles.contactoCard}`}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ mb: 10, mt: 10 }}
      >
        <Card
          className={`${styles.cardHover}`}
          sx={{ minHeight: 300, minWidth: 400, backgroundColor: "#fff" }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ p: 4 }} color="secondary" gutterBottom>
              <LocationOnIcon
                fontSize="large"
                sx={{ mr: 0.5, color: "#1786A0" }}
              />
            </Typography>
            <Typography
              variant="h6"
              component="div"
              color="secondary"
              sx={{ fontWeight: 600 }}
            >
              Rosario
            </Typography>
            <Typography
              sx={{ mb: 1.5, px: 5, py: 3 }}
              color="secondary"
              align="center"
            >
              <LocationOnIcon fontSize="small" sx={{ color: "#1786A0" }} />{" "}
              Carballo 186, 4to piso – Of. 7
            </Typography>
            <Typography
              sx={{ mb: 1.5, px: 5 }}
              color="secondary"
              align="center"
            >
              <PhoneIcon fontSize="small" sx={{ color: "#1786A0" }} /> +549 341
              3861556
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default Contacto;
