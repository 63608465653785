import React, { useEffect } from "react"
import styles from "./QuaOneClick.module.css"
import { Box, Grid, Typography, Button, Container } from "@mui/material"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { Link } from "react-router-dom"
import Formulario from "../../components/Formulario"
import QUAOnClick from "../../image/QUAOC Blanco.png"
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown"
import { Helmet } from "react-helmet"
import brochure from "../../docs/QUA One Click - Brochure.pdf"

function QuaOneClick() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Aplicaciones QUA One Click - Humanware Applications</title>
        <meta
          name="description"
          content="Conoce las aplicaciones que tenemos para tu empresa y comenzá a optimizar tus proceso."
        />
        <meta
          name="keywords"
          content="humanware, humanware applications, aplicaciones humanware, , aplicaciones humanware applications, QUA One Click"
        ></meta>
      </Helmet>
      <Box sx={{ mt: 0 }} className={`${styles.fondoQuaOneClick}`}>
        <Typography
          variant="h6"
          color="primary"
          sx={{ pt: 35, pl: 15 }}
          className={`${styles.parrafo01}`}
        >
          <Link to="/" className={`${styles.link}`}>
            Home
            <KeyboardArrowRightIcon
              color="primary"
              className={`${styles.link}`}
            />
          </Link>
          QUA One Click
        </Typography>
        <Typography
          className={`${styles.parrafo02}`}
          variant="h4"
          color="primary"
          sx={{ pt: 2, pl: 15, fontWeight: 600 }}
        >
          <img
            src={QUAOnClick}
            className={` ${styles.logoQUA}`}
            alt="QUA On Click"
          />
        </Typography>
        <a
          className={`btn ${styles.buttonBrochure}`}
          href={brochure}
          target="_blank"
          rel="noopener noreferrer"
          >
            Ver Brochure
        </a>
        <Container
          className={`${styles.botonDemo}`}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            mb: 5,
          }}
        >
          <Button variant="text" onClick={() => window.scroll(0, 1380)}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={`${styles.botonText}`}
            >
              Solicite una demo <br />
              <KeyboardDoubleArrowDownIcon
                color="primary"
                className={`${styles.botonText}`}
              />
            </Typography>
          </Button>
        </Container>
      </Box>
      <Box className={`${styles.fondoGestiondeCapital}`}>
        <Grid container spacing={0} columns={16} sx={{ pt: 0 }}>
          <Grid
            className={`${styles.containerItem}`}
            item
            xs={8}
            sx={{
              pt: 0,
              pb: 2,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{
                px: 8,
                pt: 3,
                pb: 2,
                textAlign: "start",
                fontWeight: 600,
              }}
            >
              Gestión Integral de la Calidad
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start" }}
            >
              Es un sistema focalizado en la Gestión Integral de la Calidad que
              brinda a las Organizaciones todos los módulos necesarios para la
              gestión y digitalización integral de documentación y procesos,
              contemplando las mejores prácticas modernas de implementación.
              Estos módulos a su vez pueden ser personalizados a las políticas y
              procedimientos de la empresa.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              QUA One Click permite la implementación de las normas de calidad
              más habituales, estando alineado a la estructura de las normas
              ISO.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              Especialmente diseñado para llevar adelante Auditorías, Gestión de
              desvíos, Revisión por la Dirección, Evaluación de proveedores,
              Gestión de Riesgos, Objetivos e Indicadores entre otros requisitos
              importantes.
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{ pt: 0 }}
            className={`${styles.imagen1}`}
          ></Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          columns={16}
          sx={{ pt: 0, mb: 10 }}
          className={`${styles.container} ${styles.containerFondo}`}
        >
          <Grid
            item
            xs={8}
            sx={{ pt: 0 }}
            className={`${styles.imagen2}`}
          ></Grid>
          <Grid
            className={`${styles.businessContainer} ${styles.containerItem}`}
            item
            xs={8}
            sx={{
              pt: 0,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{
                px: 8,
                pt: 3,
                pb: 2,
                textAlign: "start",
                fontWeight: 600,
              }}
            >
              Desarrollado sobre la plataforma Microsoft Dynamics 365
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start" }}
            >
              QUA One Click está integralmente desarrollado sobre la plataforma
              Microsoft Dynamics 365 para sistematizar todos los procesos
              operacionales, de Atención a clientes internos/externos y de la
              Calidad en particular.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              QUA One Click se integra nativamente con las aplicaciones de
              Office 365 (Word, Excel, Outlook, SharePoint, Yammer, Skype for
              Business) y Power BI para el armado de Tableros e Informes de
              Control.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              Esta estrategia tecnológica brinda los beneficios de Flexibilidad,
              Integración, Seguridad y Modernidad.
            </Typography>
          </Grid>
        </Grid>
        <Formulario />
      </Box>
    </>
  );
}

export default QuaOneClick;
