import React from 'react';
import CircleComponent from '../components/CircleComponent';
import './Mapa.css';
import { ArcherContainer, ArcherElement } from 'react-archer';
import styles from '../pages/AVAL One Click/AvalOneClick.module.css'
import { Typography } from '@mui/material';
import portalOC from '../image/AvalBlanco.png';
import Nosis from '../image/Nosis.png';
import WebErp from '../image/AvalOC.png';
import Epyme from '../image/Epyme.png';
import Dynamics365 from '../image/Dynamics365.png';
import PortalCliente from '../image/PortalCliente.png';
import OnboardingDigital from '../image/Onboarding.png';
import AvalOneClick from '../image/AVALOCPC.png';
import SQL from '../image/SQL.png';
import Lufe from '../image/LUFE.png';
import SocKey from '../image/PowerBI.png';

const Mapa = () => {
  return (
    <ArcherContainer strokeColor="white" strokeWidth={1}>
      <div className="Mapa">
        <div className='title'>
          <h4>Mapa de soluciones</h4>
          <img
            src={portalOC}
            alt="Logo Aval OC"
            className='logoportalOC'
          />
        </div>
        <div className="line">
          <ArcherElement id="web-erp"
           relations={[
            {
              targetId: 'ms-dynamics-crm',
              targetAnchor: 'top',
              sourceAnchor: 'bottom',
            },
            {
              targetId: 'portal-cliente',
              targetAnchor: 'top',
              sourceAnchor: 'bottom',
            },
            {
              targetId: 'onboarding-digital',
              targetAnchor: 'top',
              sourceAnchor: 'bottom',
            },
          ]}
          >
            <div>
              <CircleComponent
                name="WEB/ERP"
                imageUrl={WebErp}
                esRect
                descripcion='Acceso sencillo y seguro desde cualquier navegador mediante una URL, sin necesidad de instalar software adicional. Esto garantiza que los usuarios puedan gestionar sus operaciones desde cualquier dispositivo con conexión a internet, mejorando la accesibilidad. '
              />
            </div>
          </ArcherElement>
        </div>
        <div className="line">
          <div className="circle-container top">
            <CircleComponent 
              name="NOSIS" 
              imageUrl={Nosis} 
              descripcion='Aval One Click se potencia gracias a la integración con APIs como Nosis, LUFE y Epyme. Estas APIs son totalmente compatibles con los sistemas Microsoft. Esta integración permite una evaluación y gestión de riesgos más precisa y eficiente, potenciando la capacidad de decisión de su negocio.'
            />
            <CircleComponent 
              name="EPYME" 
              imageUrl={Epyme} 
              descripcion='Aval One Click se potencia gracias a la integración con APIs como Nosis, LUFE y Epyme. Estas APIs son totalmente compatibles con los sistemas Microsoft. Esta integración permite una evaluación y gestión de riesgos más precisa y eficiente, potenciando la capacidad de decisión de su negocio.'
            />
          </div>
        </div>
        <div className="line-dynamics">
          <ArcherElement
            id="ms-dynamics-crm"
            relations={[
              {
                targetId: 'aval-one-click',
                targetAnchor: 'top',
                sourceAnchor: 'bottom',
              },
            ]}
          ><div>
              <CircleComponent
                name="MS Dynamics CRM"
                imageUrl={Dynamics365}
                esRect
                descripcion="Está construido sobre la plataforma de Microsoft, es una herramienta robusta y eficiente para la gestión de clientes y operaciones. MS CRM facilita la centralización de la información, mejora la comunicación y potencia la eficiencia operativa."
              />
            </div>
          </ArcherElement>
        </div>
        <div className="line">
          <div className="circle-container bottom">
            <ArcherElement
              id="portal-cliente"
              relations={[
                {
                  targetId: 'aval-one-click',
                  targetAnchor: 'middle',
                  sourceAnchor: 'bottom',
                },
                {
                  targetId: 'sql',
                  targetAnchor: 'middle',
                  sourceAnchor: 'bottom',
                }
              ]}
            ><div>
              <CircleComponent
                name="Portal del Cliente y/o PyME"
                imageUrl={PortalCliente}
                esRect
                descripcion="Herramienta integral diseñada para la gestión completa de las PyMEs. Desde la visualización de montos disponibles hasta el seguimiento detallado de la facturación, nuestro portal ofrece una visión clara y completa de las operaciones de las PyMEs en los Fondos de Garantía."
              /></div>
            </ArcherElement>
            <ArcherElement
              id="onboarding-digital"
              relations={[
                {
                  targetId: 'aval-one-click',
                  targetAnchor: 'middle',
                  sourceAnchor: 'bottom',
                  style: { strokeWidth: 1, strokeColor: 'white' }
                }, 
                {
                  targetId: 'lufe',
                  targetAnchor: 'middle',
                  sourceAnchor: 'bottom',
                }
              ]}
            ><div>
              <CircleComponent
                name="Onboarding Digital"
                imageUrl={OnboardingDigital}
                esRect
                descripcion="El proceso de Onboarding Digital permite a los posibles clientes gestionar su documentación de manera autónoma. Esta funcionalidad no solo agiliza la pre-calificación sino que también asegura una revisión completa y precisa de la documentación, mejorando la experiencia del usuario y optimizando el tiempo de respuesta."
              /></div>
            </ArcherElement>
          </div>
        </div>
        <div className="line">
          <ArcherElement id="aval-one-click">
            <div>
            <CircleComponent
              name="AVAL One Click"
              imageUrl={AvalOneClick}
              esRect
              descripcion="Aval One Click se potencia gracias a la integración con APIs como Nosis, LUFE y Epyme. Estas APIs son totalmente compatibles con los sistemas Microsoft. Esta integración permite una evaluación y gestión de riesgos más precisa y eficiente, potenciando la capacidad de decisión de su negocio."

            /></div>
          </ArcherElement>
        </div>
        <div className="line">
          <div className="circle-container bottom">
            <ArcherElement
              id="sql"
              relations={[
                {
                  targetId: 'lufe',
                  targetAnchor: 'middle',
                  sourceAnchor: 'middle',
                },
              ]}
            >
              <div>
                <CircleComponent 
                  name="SQL" 
                  imageUrl={SQL} 
                  descripcion='Aval One Click se potencia gracias a la integración con APIs como Nosis, LUFE y Epyme. Estas APIs son totalmente compatibles con los sistemas Microsoft. Esta integración permite una evaluación y gestión de riesgos más precisa y eficiente, potenciando la capacidad de decisión de su negocio.'
                />
                </div>
            </ArcherElement>
            <ArcherElement
              id="lufe"
              relations={[
                {
                  targetId: 'sql',
                  targetAnchor: 'middle',
                  sourceAnchor: 'middle',
                },
              ]}
            >
              <div>
                <CircleComponent 
                  name="LUFE" 
                  imageUrl={Lufe} 
                  descripcion='Aval One Click se potencia gracias a la integración con APIs como Nosis, LUFE y Epyme. Estas APIs son totalmente compatibles con los sistemas Microsoft. Esta integración permite una evaluación y gestión de riesgos más precisa y eficiente, potenciando la capacidad de decisión de su negocio.'
                />
              </div>
            </ArcherElement>
          </div>
        </div>
        <div className="line">
          <ArcherElement id="soc-key">
            <div>
              <CircleComponent
                name="SOC Key"
                imageUrl={SocKey}
                esRect
                descripcion="Cada Fondo de Garantía puede personalizar sus paneles y visualizaciones de acuerdo con los KPI específicos que necesite monitorear. Esta funcionalidad avanzada permite un seguimiento detallado y en tiempo real, proporcionando información crítica para la toma de decisiones estratégicas."
              />
            </div>
          </ArcherElement>
        </div>
      </div>
    </ArcherContainer>
  );
};

export default Mapa;
