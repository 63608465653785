import React from "react";
import { useLocation } from "react-router-dom";
import NavBar from "./components/NavBar";
import { AppRouter } from "./Router";

function App() {
  const location = useLocation();

  // Verifica si la ruta actual es "/formulario-contacto"
  const isFormularioContactoRoute = location.pathname === "/formulario-contacto";

  return (
    <>
      {/* Mostrar la barra de navegación en todas las rutas excepto "/formulario-contacto" */}
      {!isFormularioContactoRoute && <NavBar />}
      <AppRouter />
    </>
  );
}

export default App;
