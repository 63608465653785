import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Container,
} from "@mui/material";
import React, { useEffect } from "react";
import styles from "./NuestrasAplicaciones.module.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function NuestrasAplicaciones() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Aplicaciones - Humanware Applications</title>
        <meta
          name="description"
          content="Conoce las aplicaciones que tenemos para tu empresa y comenzá a optimizar tus proceso."
        />
        <meta
          name="keywords"
          content="humanware, humanware applications, aplicaciones humanware, , aplicaciones humanware applications,"
        ></meta>
      </Helmet>
      <Container
        sx={{ mt: 0 }}
        className={`${styles.fondoNuestrasAplicaciones}`}
      >
        <Typography
          variant="h6"
          color="primary"
          sx={{ pt: 35, pl: 15 }}
          className={`${styles.parrafo01}`}
        >
          <Link to="/" className={`${styles.link}`}>
            Home
            <KeyboardArrowRightIcon
              color="primary"
              className={`${styles.link}`}
            />
          </Link>
          Nuestras Aplicaciones
        </Typography>
        <Typography
          className={`${styles.parrafo02}`}
          variant="h4"
          color="primary"
          sx={{ pt: 2, pl: 15, fontWeight: 600 }}
        >
          Acá Empieza el Crecimiento.
        </Typography>
      </Container>
      <Grid
        className={`${styles.containerAplicaciones}`}
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          className={`${styles.parrafo03}`}
          variant="subtitle1"
          sx={{ color: "#1786A0", fontWeight: "bold", mt: 5 }}
        >
          Aplicaciones
        </Typography>
        <Typography
          className={`${styles.parrafo04}`}
          variant="h4"
          color="initial"
          align="center"
          sx={{ fontWeight: "bold", p: 6, px: 30, color: "#414042" }}
        >
          Proveemos soluciones corporativas para la transformación digital de
          los Procesos.
        </Typography>
        <Typography
          className={`${styles.parrafo04}`}
          variant="h6"
          color="initial"
          align="center"
          sx={{ color: "#7A7C7F", fontWeight: "fontWeightMedium" }}
        >
          Conoce las aplicaciones que tenemos para tu empresa y comenzá a
          optimizar tus proceso.
        </Typography>
        <hr sx={{ color: "#1786A0" }} />
      </Grid>
      <Grid
        container
        className={`${styles.cardContainer}`}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ mb: 20, mt: 10, px: 2 }}
      >
        <Card
          className={`${styles.card} ${styles.cardMovil}`}
          sx={{ maxWidth: 375, maxHeight: 415, backgroundColor: "#016191" }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ p: 3 }} color="#fff" gutterBottom>
              <KeyboardDoubleArrowDownIcon fontSize="large" />
            </Typography>
            <Typography
              className={`${styles.parrafoCard01}`}
              variant="h6"
              component="div"
              color="primary"
              sx={{ fontWeight: 600 }}
            >
              People One Click
            </Typography>
            <Typography
              className={`${styles.parrafoCard02}`}
              sx={{ px: 5, pt: 3, pb: 1 }}
              color="#fff"
              align="center"
            >
              People One Click es un Employee Relationship Management que utiliza
              Microsoft Dynamics XRM para sistematizar todos los procesos
              operacionales, de Atención a clientes internos/externos y de
              Personal en particular.
            </Typography>
            <CardActions>
              <Link to="/people-one-click" className={`${styles.link}`}>
                <Button size="medium">
                  <Typography
                    className={`${styles.buttonHoverCard}`}
                    variant="subtitle2"
                    color="#757575"
                    sx={{ fontWeight: 700 }}
                  >
                    Ver Más <KeyboardArrowRightIcon />
                  </Typography>
                </Button>
              </Link>
            </CardActions>
          </CardContent>
        </Card>
        <Card
          className={`${styles.card} ${styles.cardMovil}`}
          sx={{ maxWidth: 375, minHeight: 415, backgroundColor: "#0186A0" }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ p: 3 }} color="#fff" gutterBottom>
              <KeyboardDoubleArrowDownIcon fontSize="large" />
            </Typography>
            <Typography
              className={`${styles.parrafoCard01}`}
              variant="h6"
              component="div"
              color="primary"
              sx={{ fontWeight: 600 }}
            >
              QUA One Click
            </Typography>
            <Typography
              className={`${styles.parrafoCard02}`}
              sx={{ mb: 1.5, px: 5, py: 3 }}
              color="#fff"
              align="center"
            >
              QUA One Click permite la implementación de las normas de calidad
              más habituales, estando alineado a la estructura de las normas
              ISO.
            </Typography>
            <CardActions>
              <Link to="/qua-one-click" className={`${styles.link}`}>
                <Button size="medium">
                  <Typography
                    className={`${styles.buttonHoverCard}`}
                    variant="subtitle2"
                    color="#757575"
                    sx={{ fontWeight: 700 }}
                  >
                    Ver Más <KeyboardArrowRightIcon />
                  </Typography>
                </Button>
              </Link>
            </CardActions>
          </CardContent>
        </Card>
        <Card
          className={`${styles.card} ${styles.cardMovil}`}
          sx={{ maxWidth: 375, maxHeight: 415, backgroundColor: "#016191" }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ p: 3 }} color="#fff" gutterBottom>
              <KeyboardDoubleArrowDownIcon fontSize="large" />
            </Typography>
            <Typography
              className={`${styles.parrafoCard01}`}
              variant="h6"
              component="div"
              color="primary"
              sx={{ fontWeight: 600 }}
            >
              SGR One Click
            </Typography>
            <Typography
              className={`${styles.parrafoCard02}`}
              sx={{ mb: 1.5, px: 5, py: 3 }}
              color="#fff"
              align="center"
            >
              Humanware Applications ha desarrollado SGR One Click, una
              aplicación especifica para soportar todos los procesos y
              operaciones para cumplir con las normas y regulaciones legales
            </Typography>
            <CardActions>
              <Link to="/sgr-one-click" className={`${styles.link}`}>
                <Button size="medium">
                  <Typography
                    className={`${styles.buttonHoverCard}`}
                    variant="subtitle2"
                    color="#757575"
                    sx={{ fontWeight: 700 }}
                  >
                    Ver Más <KeyboardArrowRightIcon />
                  </Typography>
                </Button>
              </Link>
            </CardActions>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default NuestrasAplicaciones;
