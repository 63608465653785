import {
  Accordion,
  AccordionDetails,
  Container,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./SobreNosotros.module.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import imgPartner from "../../image/partners.jpg";
import Brochure from "../../docs/Brochure-Humanware.pdf";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import imageParallax from "../../image/image-parallax4.png";
import fintechSGR from "../../image/fin-tech.png";
import trengSGR from "../../image/trends.png";
import fidus from "../../image/fidus.png";
import innovaSGR from "../../image/innova.png";
import confiablesSGR from "../../image/confiables.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function SobreNosotros() {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Helmet>
        <title>Quienes Somos - Humanware Applications</title>
        <meta
          name="description"
          content="Humanware Applications es una firma profesional, Partner de Microsoft, 
          que provee soluciones corporativas para la transformación digital de los Procesos."
        />
        <meta name="keywords" content="Humanware, Humanware Applications, SGR, Microsoft, Partner de Microsoft"></meta>
      </Helmet>
      <Container sx={{ mt: 0 }} className={`${styles.fondoSobreNosotros}`}>
        <Typography
          variant="h6"
          color="primary"
          sx={{ pt: 35, pl: 15 }}
          className={`${styles.parrafo01}`}
        >
          <Link to="/" className={`${styles.link}`}>
            Home
            <KeyboardArrowRightIcon
              color="primary"
              className={`${styles.link}`}
            />
          </Link>
          Sobre Nosotros
        </Typography>
        <Typography
          className={`${styles.parrafo02}`}
          variant="h4"
          color="primary"
          sx={{ pt: 2, pl: 15, fontWeight: 600 }}
        >
          Soluciones Corporativas para la Transformación digital de los
          Procesos.
        </Typography>
      </Container>
      <Grid
        className={`${styles.SobreNosotrosContainer}`}
        container
        spacing={2}
        columns={16}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          background: "#fff",
          marginTop: 10,
        }}
      >
        <Grid item xs={7} sx={{ px: 10 }} className={`${styles.sobreNosotros}`}>
          <Typography
            className={`${styles.parrafo03}`}
            variant="subtitle2"
            color="#7A7C7F"
            sx={{ px: 5, fontWeight: 500 }}
          >
            Sobre Nosotros - Quienes somos
          </Typography>
          <Typography
            className={`${styles.parrafo04}`}
            variant="h4"
            color="#414042"
            sx={{ fontWeight: 700, p: 5 }}
          >
            Somos Socios de Microsoft
          </Typography>
          <Typography
            variant="subtitle1"
            color="#7A7C7F"
            sx={{ px: 5 }}
            className={`${styles.parrafo05}`}
          >
            Humanware Applications es una firma profesional, Partner de
            Microsoft, que provee soluciones corporativas para la transformación
            digital de los Procesos. Creemos que la gestión de competencias del
            capital humano, combinado con la estrategia de implementación de
            modernas visiones, metodologías y soluciones tecnológicas, son la
            base de la Innovación, Diferenciación y Competitividad
            Organizacional.
          </Typography>
          <a
            className={`btn ${styles.buttonBrochure}`}
            href={Brochure}
            target="_blank"
            rel="noopener noreferrer"
          >
            Ver Brochure
          </a>
        </Grid>
        <Grid item xs={8} className={`${styles.imgPartnerContainer}`}>
          <img
            className={`animate__animated animate__fadeInUp`}
            src={imgPartner}
            alt="Partner"
          />
        </Grid>
        <Grid
          className={`${styles.containerPeopleSoluciones}`}
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            className={`${styles.parrafo08}`}
            variant="subtitle1"
            sx={{ color: "#1786A0", fontWeight: "bold", mt: 10 }}
          >
            People Before Strategy
          </Typography>
          <Typography
            className={`${styles.parrafo06}`}
            variant="h4"
            color="initial"
            align="center"
            sx={{ fontWeight: "bold", p: 8, color: "#414042" }}
          >
            Proveemos soluciones corporativas para la
            <br />
            transformación digital de los Procesos.
          </Typography>
          <Typography
            className={`${styles.parrafo07}`}
            variant="h6"
            color="initial"
            align="center"
            sx={{ color: "#7A7C7F", fontWeight: "fontWeightMedium" }}
          >
            En Humanware Applications creemos que la Competitividad
            Organizacional
            <br />
            se apoya en la transformación digital con soluciones y plataformas
            <br />
            de desarrollo rápido.
          </Typography>
          <Typography
            className={`${styles.parrafo07}`}
            variant="h6"
            color="initial"
            align="center"
            sx={{ color: "#7A7C7F", fontWeight: "fontWeightMedium", mt: 2 }}
          >
            Somos una empresa con experiencia y proyección, integrada por
            profesionales
            <br />
            de diferentes disciplinas para generar el desarrollo y la innovación
            <br />
            constante de nuestros productos.
          </Typography>
          <hr sx={{ color: "#1786A0" }} />
        </Grid>
        <Grid
          container
          className={`${styles.cardContainer}`}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ mb: 20, mt: 10, px: 2, mx: 4 }}
        >
          <Card
            className={`${styles.cardHover} ${styles.cardMovil}`}
            sx={{ maxWidth: 375, maxHeight: 405, backgroundColor: "#fff" }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ p: 4 }} color="secondary" gutterBottom>
                <LibraryBooksIcon fontSize="large" sx={{ color: "#1786A0" }} />
              </Typography>
              <Typography variant="h6" component="div" color="secondary" className={`${styles.parrafoCard01}`}>
                Aplicaciones
              </Typography>
              <Typography
                className={`${styles.parrafoCard02}`}
                sx={{ px: 5, pt: 3, pb: 1 }}
                color="secondary"
                align="center"
              >
                Aplicaciones Core para el negocio, como SGR One Click gestión
                Integral de SGRs, People One Click gestión de Recursos Humanos, QUA
                One Click control de calidad, CM One Click gestion construcción,
                Portal One Clcik portales webs.
              </Typography>
              <CardActions>
                <Link to="/aplicaciones" size="medium" className={`${styles.link}`}>
                  <Typography
                    className={`${styles.buttonHoverCard}`}
                    variant="subtitle2"
                    color="#757575"
                    sx={{ fontWeight: 700 }}
                  >
                    Ver Más <KeyboardArrowRightIcon />
                  </Typography>
                </Link>
              </CardActions>
            </CardContent>
          </Card>
          <Card
            className={`${styles.cardHover} ${styles.cardMovil}`}
            sx={{ maxWidth: 375, minHeight: 415, backgroundColor: "#fff" }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ p: 4 }} color="secondary" gutterBottom>
                <QuestionAnswerIcon
                  fontSize="large"
                  sx={{ color: "#1786A0" }}
                />
              </Typography>
              <Typography variant="h6" component="div" color="secondary" className={`${styles.parrafoCard01}`}>
                Corporate Performance Management
              </Typography>
              <Typography
                 className={`${styles.parrafoCard02}`}
                sx={{ mb: 1.5, px: 5, py: 3 }}
                color="secondary"
                align="center"
              >
                Corporate Performance Management con Kepion Planning y Power BI
                como soluciones para gestión estratégica y táctica del negocio.
              </Typography>
            </CardContent>
          </Card>
          <Card
            className={`${styles.cardHover} ${styles.cardMovil}`}
            sx={{ maxWidth: 375, maxHeight: 405, backgroundColor: "#fff" }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ p: 4 }} color="secondary" gutterBottom>
                <LibraryAddCheckIcon
                  fontSize="large"
                  sx={{ color: "#1786A0" }}
                />
              </Typography>
              <Typography variant="h6" component="div" color="secondary"  className={`${styles.parrafoCard01}`}>
                Capacitaciones
              </Typography>
              <Typography
                 className={`${styles.parrafoCard02}`}
                sx={{ mb: 1.5, px: 5, py: 3 }}
                color="secondary"
                align="center"
              >
                Capacitación y desarrollo de competencias internas para la
                efectiva transferencia de know how en la tecnología y nuestras
                soluciones.
              </Typography>
              <CardActions>
                <Link to="/capacitaciones" size="medium" className={`${styles.link}`}>
                  <Typography
                    className={`${styles.buttonHoverCard}`}
                    variant="subtitle2"
                    color="#757575"
                    sx={{ fontWeight: 700 }}
                  >
                    Ver Más <KeyboardArrowRightIcon />
                  </Typography>
                </Link>
              </CardActions>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            className={`${styles.parrafo06}`}
            variant="h4"
            color="initial"
            align="center"
            sx={{ fontWeight: "bold", p: 8, color: "#414042" }}
          >
            Soluciones de negocio y transferencia de know how
            <br />
            transformación digital.
          </Typography>
          <Typography
            className={`${styles.parrafo07}`}
            variant="h6"
            color="initial"
            align="center"
            sx={{ color: "#7A7C7F", fontWeight: "fontWeightMedium" }}
          >
            Modernas visiones y metodologías de trabajo que ayudan a las
            compañías a ser
            <br />
            más competitivas y a diferenciarse dentro del mercado.
          </Typography>
          <hr sx={{ color: "#1786A0" }} />
        </Grid>
        <Grid
          className={`${styles.containerPrefooter}`}
          container
          spacing={0}
          columns={16}
          sx={{
            mt: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <Grid className={`${styles.imgPrefooter}`} item xs={7}>
            <img
              src={imageParallax}
              className={`${styles.imageParallax}`}
              alt="imagen"
            />
          </Grid>
          <Grid item xs={7} className={`${styles.acordionPrefooter}`}>
            <Accordion
              className={`${styles.acordionItem}`}
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{ backgroundColor: "#EDF9ED", mb: 3 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#414042" }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography color="#414042" sx={{ py: 2, fontWeight: 600 }}>
                  Humanware Training Center
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="#414042">
                  HW Applications cuenta con un centro equipado para brindar
                  capacitaciones en aula y/o talleres de trabajo en tecnologías
                  Microsoft para ayudar a las compañías a lograr su
                  transformación digital. Nuestro principal objetivo es
                  transferir conocimientos en tecnologías, modernas visiones y
                  metodologías de trabajo que ayudan a las compañías a ser más
                  competitivas y a diferenciarse dentro del mercado.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={`${styles.acordionItem}`}
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{ backgroundColor: "#EDF9ED", mb: 3 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#414042" }} />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography color="#414042" sx={{ py: 2, fontWeight: 600 }}>
                  Software Factory
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="#414042">
                  En la ciudad de Rosario, Argentina, en la zona agro-industrial
                  mas importante del país, HW Applications ha montado su
                  Software Factory, especializada en Soluciones Tecnológicas
                  Microsoft, desde donde provee soporte y servicios de valor
                  agregado a nuestros clientes y socios de negocios sobre
                  nuestras Aplicaciones. Con un equipo altamente capacitado y
                  certificado, HW Software Factory personaliza las soluciones de
                  acuerdo a los proyectos, objetivos y necesidades de cada
                  cliente.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Grid
          className={`${styles.containerPrefooter}`}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            py: 8,
          }}
          container
          spacing={2}
        >
          <Grid item className={`${styles.imagenesContainer}`}>
            <img
              src={fintechSGR}
              className={`${styles.imagenesLogo}`}
              alt="finanzas y Creditos"
            />
          </Grid>
          <Grid item className={`${styles.imagenesContainer}`}>
            <img
              src={trengSGR}
              className={`${styles.imagenesLogo}`}
              alt="Sancor Seguros"
            />
          </Grid>
          <Grid item className={`${styles.imagenesContainer}`}>
            <img
              src={fidus}
              className={`${styles.imagenesLogo}`}
              alt="Origenes"
            />
          </Grid>
          <Grid item className={`${styles.imagenesContainer}`}>
            <img
              src={innovaSGR}
              className={`${styles.imagenesLogo}`}
              alt="Megatlon"
            />
          </Grid>
          <Grid item className={`${styles.imagenesContainer}`}>
            <img
              src={confiablesSGR}
              className={`${styles.imagenesLogo}`}
              alt="Bolsa Comercio"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default SobreNosotros;
