import React from "react";
import FormControl from "@mui/material/FormControl";
import { Controller, useFormState } from "react-hook-form";
import CurrencyTextField from "@lupus-ai/mui-currency-textfield";

const CustomCurrencyField = ({
  name,
  label,
  Component,
  rules,
  helperText,
  type,
  readOnly = false,
  req,
  ...restProps
}) => {
  const formState = useFormState();

  return (
    <Controller
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          sx={{
            color: "green",
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "green", // Cambia este valor al color deseado
              },
            "& .MuiFormLabel-root.Mui-focused": {
              color: "green", // Cambia este valor al color deseado
            },
          }}
          error={Boolean(formState.errors && formState.errors[name])}
        >
          <CurrencyTextField
            label={label}
            variant="outlined"
            value={value}
            disabled={readOnly}
            currencySymbol="$"
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={(event, value) => onChange(value)}
            required={req}
            helperText={formState.errors[name] ? helperText : null}
            error={Boolean(formState.errors && formState.errors[name])}
            placeholder="0.00"
            {...restProps}
          />
        </FormControl>
      )}
      rules={rules}
    />
  );
};

export default CustomCurrencyField;
