import React, { useEffect } from "react";
import img1 from "../image/digital-slider3.jpg";
import img2 from "../image/digital-slider1.jpg";
import img3 from "../image/finance-slider1.jpg";
import img4 from "../image/digital-slider2.jpg";
import img5 from "../image/digital-slider4.jpg";
import img6 from "../image/digital-slider5.jpg";
import img7 from "../image/digital-slider6.jpg";
import img8 from "../image/digital-slider7.jpg";
import logo from "../image/logo-humanware-blanco.png";
import logo2 from "../image/1Logo-human.png";
import SGROC from "../image/SGROC Blanco.png";
import HROnClick from "../image/HROC Blanco.png";
import QUAOnClick from "../image/QUAOC Blanco.png";
import CMOnClick from "../image/CMOC Blanco.png";
import HROnClickEducativa from "../image/HROCEducativa.png";
import portalOnClick from "../image/POC Blanco.png";
import avalOnClick from "../image/AvalBlanco.png";
import PeopleOneClick from '../image/People One Click Logo.png'
import PeopleEducativa from '../image/People Educativa.png'
import styles from "./Carrusel.module.css";
import "animate.css";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Link } from "react-router-dom";

const Carrusel = () => {
  useEffect(() => {
    setTimeout(() => {
      remove();
    }, 3000);
  }, []);

  const remove = () => {
    document.getElementById("slide1").classList.add("disable");
    setTimeout(() => {
      document.getElementById("slide2").classList.remove();
    }, 1000);
  };

  return (
    <div
      id="carouselExampleFade"
      className="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={img1} className={`d-block w-100 ${styles.img}`} alt="Imagen HW" />
          <div
            id="slide1"
            className="carousel-caption d-flex justify-content-center align-items-center"
          >
            <img
              src={logo}
              className={`d-block w-50 animate__animated animate__fadeOut animate__delay-3s ${styles.logo}`}
              alt="Logo HW"
            />
          </div>
          <div
            id="slide2"
            className={`carousel-caption d-flex justify-content-center align-items-center ${styles.disable}`}
          >
            <div
              className={`d-flex flex-column justify-content-center align-items-center animate__animated animate__zoomIn animate__delay-3s ${styles.divSlide1}`}
            >
              <img
                src={logo2}
                className={`d-block ${styles.logo2}`}
                alt="Logo HW"
              />
              <h5 className={` ${styles.parrafo1}`}>
                Creemos que la Competitividad Organizacional
                <br />
                se apoya en la tranformación digital
                <br />
                con soluciones y plataformas de desarrollo rápido
              </h5>
              <button
                className={`btn ${styles.boton} animate__animated animate__slideInDown animate__delay-2s`}
                onClick={() => window.scroll(0, 520)}
              >
                <KeyboardDoubleArrowDownIcon fontSize="large" />
              </button>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img src={img3} className={`d-block w-100 ${styles.img}`} alt="Imagen" />
          <div
            className={`${styles.divSlide2} carousel-caption d-flex flex-column justify-content-center align-items-center`}
            id="slide1"
          >
            <img src={SGROC} className={` ${styles.logoSGR}`} alt="Logo SGR" />
            <h5 className={`${styles.parrafoSGR}`}>
              Solución integral para la transformación
              <br />
              digital de Sociedades de Garantías Reciprocas
            </h5>
            <Link className={`btn ${styles.botonSGR}`} to="/sgr-one-click">
              Ver Más
            </Link>
          </div>
        </div>
        <div className="carousel-item">
          <img src={img2} className={`d-block w-100 ${styles.img}`} alt="Imagen" />
          <div
            className={`${styles.divSlide2} carousel-caption d-flex flex-column justify-content-center align-items-center`}
            id="slide1"
          >
            <img
              src={PeopleOneClick}
              className={` ${styles.logoSGR}`}
              alt="People On Click"
            />
            <h5 className={`${styles.parrafoSGR}`}>Nuestra solución Core</h5>
            <Link className={`btn ${styles.botonSGR}`} to="/people-one-click">
              Ver Más
            </Link>
          </div>
        </div>
        <div className="carousel-item">
          <img src={img4} className={`d-block w-100 ${styles.img}`} alt="Imagen" />
          <div
            className={`${styles.divSlide2} carousel-caption d-flex flex-column justify-content-center align-items-center`}
            id="slide1"
          >
            <img
              src={QUAOnClick}
              className={` ${styles.logoSGR}`}
              alt="QUA On Click"
            />
            <h5 className={`${styles.parrafoSGR}`}>
              Gestion integral de la Calidad
            </h5>
            <Link className={`btn ${styles.botonSGR}`} to="/qua-one-click/">
              Ver Más
            </Link>
          </div>
        </div>
        <div className="carousel-item">
          <img src={img5} className={`d-block w-100 ${styles.img}`} alt="Imagen" />
          <div
            className={`${styles.divSlide2} carousel-caption d-flex flex-column justify-content-center align-items-center`}
            id="slide1"
          >
            <img
              src={CMOnClick}
              className={` ${styles.logoSGR}`}
              alt="CM On Click"
            />
             <h5 className={`${styles.parrafoSGR}`}>
              CM One Click es un sistema de Gestión de la Construcción
            </h5>
            <Link className={`btn ${styles.botonSGR}`} to="/cm-one-click/">
              Ver Más
            </Link>
          </div>
        </div>
        <div className="carousel-item">
          <img src={img6} className={`d-block w-100 ${styles.img}`} alt="Imagen" />
          <div
            className={`${styles.divSlide2} carousel-caption d-flex flex-column justify-content-center align-items-center`}
            id="slide1"
          >
            <img
              src={PeopleEducativa}
              className={` ${styles.logoSGR}`}
              alt="People On Click Educativa"
            /> 
            <h5 className={`${styles.parrafoSGR}`}>
              Gestión de Capital Humano para Instituciones Educativas
            </h5>
            <Link
              className={`btn ${styles.botonSGR}`}
              to="/people-one-click-educativa/"
            >
              Ver Más
            </Link>
          </div>
        </div>
        <div className="carousel-item">
          <img src={img7} className={`d-block w-100 ${styles.img}`} alt="Imagen" />
          <div
            className={`${styles.divSlide2} carousel-caption d-flex flex-column justify-content-center align-items-center`}
            id="slide1"
          >
            <img
              src={portalOnClick}
              className={` ${styles.logoSGR}`}
              alt="portal On Click"
            />
            <h5 className={`${styles.parrafoSGR}`}>
            </h5>
            <Link
              className={`btn ${styles.botonSGR}`}
              to="/portal-one-click"
            >
              Ver Más
            </Link>
          </div>
        </div>
        <div className="carousel-item">
          <img src={img8} className={`d-block w-100 ${styles.img}`} alt="Imagen" />
          <div
            className={`${styles.divSlide2} carousel-caption d-flex flex-column justify-content-center align-items-center`}
            id="slide1"
          >
            <img
              src={avalOnClick}
              className={` ${styles.logoSGR}`}
              alt="Aval On Click"
            />
            <h5 className={`${styles.parrafoSGR}`}>
              Gestion integral de Garantías, en un click
            </h5>
            <Link
              className={`btn ${styles.botonSGR}`}
              to="/aval-one-click"
            >
              Ver Más
            </Link>
          </div>
        </div>
      </div>
      <button
        className={`carousel-control-prev ${styles.botonCarrusel}`}
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className={`carousel-control-next ${styles.botonCarrusel}`}
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carrusel;
