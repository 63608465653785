import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, IconButton } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import CustomCurrencyField from "../CustomCurrencyField ";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";

const ToastError = (msj) => {
  toast.error(msj, {
    theme: "dark",
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
//API credentials Sandbox
// AdUc6tHCit2BZlAXLMvTjVw7wB03fOdsGCS_hCXc3eDw5xTd71DuBZcyaXgq-mZLvL2qztFNmVjcEtFy

const ModalPayPal = ({ open, handleClose }) => {
  const methods = useForm({
    shouldUnregister: false,
    mode: "onChange",
    shouldFocusError: true,
  });

  const { getValues } = methods;

  const createOrder = (data, actions) => {
    const monto = getValues("montoPago") || "";
    console.log(monto);
    if (!monto) {
      ToastError("Por favor, ingrese un monto antes de continuar.");
      return false;
    }

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: monto,
          },
        },
      ],
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle sx={{ justifyContent: "center", my: 1 }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            p: 1,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              color: "red",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ minWidth: { md: "40vw", lg: "25vw" } }}>
        <FormProvider {...methods}>
          <CustomCurrencyField
            name="montoPago"
            label="Monto a pagar (Dolares): "
            size="small"
          />
        </FormProvider>
        <PayPalScriptProvider
          options={{
            "client-id":
              "AdUc6tHCit2BZlAXLMvTjVw7wB03fOdsGCS_hCXc3eDw5xTd71DuBZcyaXgq-mZLvL2qztFNmVjcEtFy",
            currency: "USD",
          }}
        >
          <PayPalButtons
            style={{ layout: "horizontal" }}
            createOrder={createOrder}
          />
        </PayPalScriptProvider>
      </DialogContent>
    </Dialog>
  );
};
export default ModalPayPal;
