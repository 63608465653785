import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import  NavBar  from "./NavBar";

export const RouterLayout = () => {
  
  return (
    <>
      <NavBar />
      <Outlet />
      <Footer/>
    </>
  );
};