import React, { useEffect } from "react";
import styles from "./CmOneClick.module.css";
import { Box, Grid, Typography, Container, Button } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "react-router-dom";
import Formulario from "../../components/Formulario";
import CMOC from "../../image/CMOC Blanco.png";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Helmet } from "react-helmet";

function CmOneClick() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Aplicaciones CM One Click - Humanware Applications</title>
        <meta
          name="description"
          content="Conoce las aplicaciones que tenemos para tu empresa y comenzá a optimizar tus proceso."
        />
        <meta
          name="keywords"
          content="humanware, humanware applications, aplicaciones humanware, , aplicaciones humanware applications, CM One Click"
        ></meta>
      </Helmet>
      <Box sx={{ mt: 0 }} className={`${styles.fondoCmOneClick}`}>
        <Typography
          variant="h6"
          color="primary"
          sx={{ pt: 35, pl: 15 }}
          className={`${styles.parrafo01}`}
        >
          <Link to="/" className={`${styles.link}`}>
            Home
            <KeyboardArrowRightIcon
              color="primary"
              className={`${styles.link}`}
            />
          </Link>
          CM One Click
        </Typography>
        <Typography
          className={`${styles.parrafo02}`}
          variant="h4"
          color="primary"
          sx={{ pt: 2, pl: 15, fontWeight: 600 }}
        >
          <img
            src={CMOC}
            className={` ${styles.logoCMOC}`}
            alt="Logo CM On Click"
          />
        </Typography>
        <Container
          className={`${styles.botonDemo}`}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            mb: 5,
          }}
        >
          <Button variant="text" onClick={() => window.scroll(0, 1280)}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={`${styles.botonText}`}
            >
              Solicite una demo <br />
              <KeyboardDoubleArrowDownIcon
                color="primary"
                className={`${styles.botonText}`}
              />
            </Typography>
          </Button>
        </Container>
      </Box>
      <Box className={`${styles.fondoGestiondeCapital}`}>
        <Grid container spacing={0} columns={16} sx={{ pt: 0 }}>
          <Grid
            className={`${styles.containerItem}`}
            item
            xs={8}
            sx={{
              pt: 0,
              pb: 2,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{
                px: 8,
                pt: 3,
                pb: 2,
                textAlign: "start",
                fontWeight: 600,
              }}
            >
              CM One Click es un sistema de Gestión de la Construcción
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start" }}
            >
              Gestiona toda tu empresa de forma online cómo, cuándo y desde dónde quieras<br/>
              Saldos de cajas en "tiempo real" de todas las obras ante cada movimiento. Caja General vs Cajas por Obras.<br/>
              "Estimación" de presupuestos (Solicitud de Presupuesto Rapido al inicializar obra, proyección de costos)<br/>
              Trackeo de Solicitudes de Presupuestos Reales por Obra.<br/>
              Comparativas Presupuesto Rapido vs Presupuesto Real (Reporte en PowerBI, data importante a revisar, gran valor al producto)<br/>
              Seguimiento de Obras: Circuito de registración de los avances por Obra, específicamente por Unidades.<br/>
              Volcado/generación rapida de Presupuestos en Pedidos, pudiendo ser "Pedidos de Materiales/MO" o "Acopios" (por mercadería o por Lista de Precio)<br/>
              </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{ pt: 0 }}
            className={`${styles.imagen1}`}
          ></Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          columns={16}
          sx={{ pt: 0, mb: 10 }}
          className={`${styles.container} ${styles.containerFondo}`}
        >
          <Grid
            item
            xs={8}
            sx={{ pt: 0 }}
            className={`${styles.imagen2}`}
          ></Grid>
          <Grid
            className={`${styles.businessContainer} ${styles.containerItem}`}
            item
            xs={8}
            sx={{
              pt: 0,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{
                px: 8,
                pt: 3,
                pb: 2,
                textAlign: "start",
                fontWeight: 600,
              }}
            >
              Toda tu obra bajo control
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start" }}
            >
              Remitaje de mercadería, poder registrar entregas/devoluciones/faltantes de retirar. Todo esto con visualizaciones de saldos.<br/>
              Generación de Comprobantes desde mismos pedidos / acopios. Posibilidad de facturación al 100% o en menos porcentaje.<br/>
              Ordenes de pagos a proveedores/servicios, con aprobación de gerencia. Aplicaciones = match entre Comprobante y ODP.<br/>
              En Cajas: Visualización de las partes que involucran el movimientos de saldos. Composición de saldos.<br/>
              Posibilidad de generar movimientos de cash entre cajas, ingresos/egresos.<br/>
            </Typography>
          </Grid>
        </Grid>
        <Formulario />
      </Box>
    </>
  );
}

export default CmOneClick;
