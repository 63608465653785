import React, { useEffect } from "react";
import styles from "./DynamicsCRM365.module.css";
import { Box, Grid, Typography, Container, Button } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "react-router-dom";
import Formulario from "../../components/Formulario";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Helmet } from "react-helmet";

function DynamicsCRM365() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Aplicaciones Dynamics CRM 365 - Humanware Applications</title>
        <meta
          name="description"
          content="Conoce las aplicaciones que tenemos para tu empresa y comenzá a optimizar tus proceso."
        />
        <meta
          name="keywords"
          content="humanware, humanware applications, aplicaciones humanware, , aplicaciones humanware applications, Dynamics CRM 365"
        ></meta>
      </Helmet>
      <Box sx={{ mt: 0 }} className={`${styles.fondoPortalOneClick}`}>
        <Typography
          variant="h6"
          color="primary"
          sx={{ pt: 35, pl: 15 }}
          className={`${styles.parrafo01}`}
        >
          <Link to="/" className={`${styles.link}`}>
            Home
            <KeyboardArrowRightIcon
              color="primary"
              className={`${styles.link}`}
            />
          </Link>
          Dynamics CRM 365
        </Typography>
        <Typography
          className={`${styles.parrafo02}`}
          variant="h4"
          color="primary"
          sx={{ pt: 2, pl: 15, fontWeight: 600 }}
        >
          {/* <img
            src={logoCRM}
            className={` ${styles.logoportalOC}`}
            alt="Logo Portal OC"
          /> */}
        </Typography>
        <Container
          className={`${styles.botonDemo}`}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            mb: 5,
          }}
        >
          <Button variant="text" onClick={() => window.scroll(0, 1090)}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={`${styles.botonText}`}
            >
              Solicite una demo <br />
              <KeyboardDoubleArrowDownIcon
                color="primary"
                className={`${styles.botonText}`}
              />
            </Typography>
          </Button>
        </Container>
      </Box>
      <Box className={`${styles.fondoGestiondeCapital}`}>
        <Grid container spacing={0} columns={16} sx={{ pt: 0 }}>
          <Grid
            className={`${styles.containerItem}`}
            item
            xs={8}
            sx={{
              pt: 0,
              pb: 2,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{
                px: 8,
                pt: 3,
                pb: 2,
                textAlign: "start",
                fontWeight: 600,
              }}
            >
              Somos expertos en CRM
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start" }}
            >
              Integramos lo mejor de los dos mundos dentro de Power Platform y
              Microsoft Dynamics CRM Tenemos una basta experiencia en
              implementaciones de soluciones para diferentes sectores de la
              industria y tecnología.
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{ pt: 0 }}
            className={`${styles.imagen1}`}
          ></Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          columns={16}
          sx={{ pt: 0, mb: 10 }}
          className={`${styles.container} ${styles.containerFondo}`}
        >
          <Grid
            item
            xs={8}
            sx={{ pt: 0 }}
            className={`${styles.imagen2}`}
          ></Grid>
          <Grid
            className={`${styles.businessContainer} ${styles.containerItem}`}
            item
            xs={8}
            sx={{
              pt: 0,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{
                px: 8,
                pt: 3,
                pb: 2,
                textAlign: "start",
                fontWeight: 600,
              }}
            >
              El software de administración del cliente
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start" }}
            >
              El software de administración del cliente (CRM) no solo consiste
              en la automatización de las ventas y los servicios. También
              consiste en interactuar con los clientes, anticipar su recorrido y
              superar sus necesidades. Las soluciones de CRM pueden optimizar
              los flujos de trabajo, fomentar la colaboración, mejorar las
              comunicaciones y ofrecer una mejor experiencia al cliente para
              empresas de todos los tamaño. Los sistemas de CRM ya no son
              exclusivos de las grandes corporaciones. Las empresas de menor
              tamaño también pueden encontrar soluciones que se adapten a sus
              necesidades y crezcan al ritmo de su negocio.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              <li>Conseguir una perspectiva integral de los clientes.</li>
              <li>Conectar las ventas y el marketing.</li>
              <li>Cerrar más operaciones comerciales.</li>
            </Typography>
          </Grid>
        </Grid>
        <Formulario />
      </Box>
    </>
  );
}

export default DynamicsCRM365;
