import { Button, Grid, Typography, Stack } from "@mui/material";
import React from "react";
import styles from "./Footer.module.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

function Footer() {
  return (
    <>
      <Grid
        container
        spacing={2}
        className={`${styles.FondoSumate}`}
        sx={{
          mt: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          color="primary"
          className={`${styles.parrafoSumate}`}
        >
          ¡Súmate a este crecimiento!
        </Typography>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Link to="/contacto" className={`${styles.linkTocaAqui}`}>
            <Button
              sx={{
                backgroundColor: "#28C23E",
                borderRadius: 25,
                px: 5,
                py: 2,
              }}
            >
              TOCA AQUÍ PARA EMPEZAR
            </Button>
          </Link>
          <Typography
            variant="subtitle1"
            color="primary"
            sx={{ mt: 2 }}
            className={`${styles.parrafoAnimate}`}
          >
            Animate a crecer
          </Typography>
        </Grid>
      </Grid>
      <Grid
        className={`${styles.containerFooterFinal}`}
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "flex-start",
          backgroundColor: "#081526",
          py: 10,
          pb: 5,
        }}
      >
        <Grid item>
          <Typography
            variant="h6"
            color="#fff"
            sx={{ pb: 3, fontWeight: "600" }}
          >
            Contacto
          </Typography>
          <Typography variant="subtitle1" color="#fff" sx={{ pb: 2 }}>
            <span className={styles.bold}>Rosario</span>
            <br />
            <LocationOnIcon color="primary" fontSize="small" sx={{ mr: 0.5 }} />
            Carballo 186, 4to piso – Of. 7
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          item
        >
          <Typography
            variant="h6"
            color="#fff"
            sx={{ pb: 3, fontWeight: "600" }}
          >
            Aplicaciones
          </Typography>
          <Link to="sgr-one-click" className={`${styles.link}`}>
            SGR One Click
          </Link>
          <Link to="people-one-click" className={`${styles.link}`}>
            People One Click
          </Link>
          <Link to="qua-one-click" className={`${styles.link}`}>
            QUA One Click
          </Link>
          <Link to="/cm-one-click" className={`${styles.link}`}>
            CM One Click
          </Link>
          <Link to="/people-one-click-educativa" className={`${styles.link}`}>
            People One Click Educativa
          </Link>
          <Link to="/aval-one-click" className={`${styles.link}`}>
            AVAL One Click
          </Link>
          <Link to="/dynamics-CRM-365" className={`${styles.link}`}>
            Dynamics CRM 365
          </Link>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "flex-start",
          }}
          item
        >
          <Typography
            variant="h6"
            color="#fff"
            sx={{ pb: 3, fontWeight: "600" }}
          >
            Páginas
          </Typography>
          <Link to="/quienes-somos" className={`${styles.link}`}>
            Quienes Somos
          </Link>
          <Link to="/aplicaciones" className={`${styles.link}`}>
            Aplicaciones
          </Link>
          <Link to="/capacitaciones" className={`${styles.link}`}>
            Capacitaciones
          </Link>
          <Link to="/contacto" className={`${styles.link}`}>
            Contacto
          </Link>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2} sx={{ mr: "2vw" }}>
            <a href="https://www.facebook.com/hrfactors/" target="_blank">
              <FacebookIcon
                color="primary"
                className={`${styles.iconFacebook}`}
              />
            </a>
            <a
              href="https://www.instagram.com/hwapplications/ "
              target="_blank"
            >
              <InstagramIcon
                color="primary"
                className={`${styles.iconInstagram}`}
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCzzwRyquqFBwSCSU8Gwww_A"
              target="_blank"
            >
              <YouTubeIcon
                color="primary"
                className={`${styles.iconYoutube}`}
              />
            </a>
            <a href="https://www.linkedin.com/company/humanware-applications/" target="_blank">
              <LinkedInIcon
                color="primary"
                className={`${styles.iconLinkedin}`}
              />
            </a>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        className={`${styles.fondoAlFinal}`}
        sx={{
          display: "flex",
          flexDirection: {md:"row", xs:"column"},
          justifyContent: "space-around",
          alignItems: "center",
          py:"1rem",
          gap:2
        }}
      >
        <Typography variant="subtitle1" color="primary" sx={{fontSize:{ xs:".9rem", md:"1rem"}}}>
          {" "}
          © Derechos Humanware Applications.
        </Typography>
        <Link to="/politica-de-privacidad" className={`${styles.linkPoliticas}`}>
        <Typography variant="subtitle1" color="primary" sx={{fontSize:{ xs:".9rem", md:"1rem"}}}>
            Ver políticas de privacidad
          </Typography>
        </Link>
        <Stack direction="row" spacing={2}>
          <Typography variant="subtitle1" color="primary" sx={{fontSize:{ xs:".9rem", md:"1rem"} }}>
            Humanware Applications
          </Typography>
        </Stack>
      </Grid>
    </>
  );
}

export default Footer;
