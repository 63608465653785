import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import tokenReducer from "../Token";
import contactosReducer from "../contacto";

const appReducer = combineReducers({
  token: tokenReducer,
  contacto: contactosReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "CERRAR_SESION") {
    state = undefined;
  }

  return appReducer(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
  return store;
}
