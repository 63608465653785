import React, { useEffect } from "react";
import styles from "./HrOneClickEducativa.module.css";
import { Box, Grid, Typography, Container, Button } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "react-router-dom";
import Formulario from "../../components/Formulario";
import PeopleEducativa from "../../image/People Educativa.png";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Helmet } from "react-helmet";
import brochure from "../../docs/People One Click - Educativa - Modulos Operacionales.pdf"

function HrOneClickEducativa() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Aplicaciones People One Click Educativa - Humanware Applications</title>
        <meta
          name="description"
          content="Conoce las aplicaciones que tenemos para tu empresa y comenzá a optimizar tus proceso."
        />
        <meta
          name="keywords"
          content="humanware, humanware applications, aplicaciones humanware, , aplicaciones humanware applications, People One Click Educativa"
        ></meta>
      </Helmet>
      <Box sx={{ mt: 0 }} className={`${styles.fondoHrOneClickEducativa}`}>
        <Typography
          variant="h6"
          color="primary"
          sx={{ pt: 35, pl: 15 }}
          className={`${styles.parrafo01}`}
        >
          <Link to="/" className={`${styles.link}`}>
            Home
            <KeyboardArrowRightIcon
              color="primary"
              className={`${styles.link}`}
            />
          </Link>
          People One Click Educativa
        </Typography>
        <Typography
          className={`${styles.parrafo02}`}
          variant="h4"
          color="primary"
          sx={{ pt: 2, pl: 15, fontWeight: 600 }}
        >
          <img src={PeopleEducativa} className={` ${styles.logoHR}`} alt="Logo HR" />
        </Typography>
        <a
          className={`btn ${styles.buttonBrochure}`}
          href={brochure}
          target="_blank"
          rel="noopener noreferrer"
          >
            Ver Brochure
        </a>
        <Container
          className={`${styles.botonDemo}`}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            mb: 5,
          }}
        >
          <Button variant="text" onClick={() => window.scroll(0, 1190)}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={`${styles.botonText}`}
            >
              Solicite una demo <br />
              <KeyboardDoubleArrowDownIcon
                color="primary"
                className={`${styles.botonText}`}
              />
            </Typography>
          </Button>
        </Container>
      </Box>
      <Box className={`${styles.fondoGestiondeCapital}`}>
        <Grid container spacing={0} columns={16} sx={{ pt: 0 }}>
          <Grid
            className={`${styles.containerItem}`}
            item
            xs={8}
            sx={{
              pt: 0,
              pb: 2,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{
                px: 8,
                pt: 3,
                pb: 2,
                textAlign: "start",
                fontWeight: 600,
              }}
            >
              Gestión de Capital Humano para Instituciones Educativas
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start" }}
            >
              Permite la gestión de nómina Docente (o Codocentes que cumplen Rol
              Docente).
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              People One Click Educativa permite la gestión integral de la actividad
              Docente: la Asignación de Docentes a las Divisiones (Cátedras o
              Seminarios), y a distintas Actividades Docentes (Proyectos de
              investigación, Team Teaching y diferentes Experiencias Educativas)
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              Contempla la generación de Evaluaciones 360 de los Docentes,
              considerando encuestas de Alumnos, Pares y Líderes, así como la
              Autoevaluación. Permite el cálculo de Ranking Docente, bajo
              determinados parámetros, y el calculo de Créditos por Períodos
              para cada Docente. Permite la gestión de Observaciones Aulicas y
              Mentoreos Docente, así como las Entrevistas de Devolución o
              Feedback.
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{ pt: 0 }}
            className={`${styles.imagen1}`}
          ></Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          columns={16}
          sx={{ pt: 0, mb: 10 }}
          className={`${styles.container} ${styles.containerFondo}`}
        >
          <Grid
            item
            xs={8}
            sx={{ pt: 0 }}
            className={`${styles.imagen2}`}
          ></Grid>
          <Grid
            className={`${styles.businessContainer} ${styles.containerItem}`}
            item
            xs={8}
            sx={{
              pt: 0,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{
                px: 8,
                pt: 3,
                pb: 2,
                textAlign: "start",
                fontWeight: 600,
              }}
            >
              Gestión Docente + Gestion Codocente todo en un Click
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start" }}
            >
              People One Click Educativa permite la gestión de la base de
              Aspirantes, Ayudantes y Adscriptos. Incluye la gestión del
              Concurso Docente, su generación automática ante una asignación
              vacante, y las diferentes Postulaciones asociadas al mismo.
              Además, permite el seguimiento de las Clases y Entrevistas de
              Oposición.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              Permite la gestión del Presupuesto Docente, aperturado por
              Periodos, Subperiodos y Áreas. Además, gestiona el cálculo
              automático de las Novedades Docentes, todo en un esquema de
              parametrización de calculo que permite la adaptación del mismo a
              diferentes escenarios.
            </Typography>
          </Grid>
        </Grid>
        <Formulario />
      </Box>
    </>
  );
}

export default HrOneClickEducativa;
