import React from "react";
import Carousel from "react-grid-carousel";
import styles from "./MultiCarrusel.module.css";
import finanzasYC from "../image/finanzas-y-creditos.png";
import SancorSeguros from "../image/sancor-seguros.png";
import origenes from "../image/Origenes.png";
import megatlon from "../image/megatlon.png";
import bolsaComercio from "../image/bolsaComercio.png";
import caputo from "../image/caputo.png";
import pampa from "../image/pampa.png";
import bancocomafi from "../image/bancocomafi.png";
import cauciones from "../image/cauciones.png";
import siglo21 from "../image/siglo21.png";
import lauraSasia from "../image/laura-sasia.png";
import zf from "../image/zf.png";
import popArgentina from "../image/popArgentina.png";
import arVida from "../image/ArVida.png";

function MultiCarrusel() {
  return (
    <>
      <Carousel showDots cols={6} rows={1} gap={5} loop>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={finanzasYC} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={SancorSeguros} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={origenes} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={megatlon} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={bolsaComercio} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={caputo} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
  
            }}
          >
            <img width="100%" src={pampa} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={arVida} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={bancocomafi} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={cauciones} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={siglo21} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={lauraSasia} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={zf} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={popArgentina} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        {/* ... */}
      </Carousel>
    </>
  );
}

export default MultiCarrusel;
