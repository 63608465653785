import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Grid, Stack } from "@mui/material";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import DraftsIcon from "@mui/icons-material/Drafts";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "../image/logo-humanware-blanco.png";
import styles from "./NavBar.module.css";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ModalPayPal from "./Modales/ModalPayPal";

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [log, setLog] = useState(true);
  const [scroll, setScroll] = useState(0);
  const [open, setOpen] = useState(false);

  window.onscroll = function () {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    if (scroll >= 100) {
      setLog(false);
    }
    if (scroll == 0) {
      setLog(true);
    }
  }, [scroll, log]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <AppBar
        id="appBar"
        className={`${styles.ocultar} ${styles.appBar} ${log ? "" : styles.fondoScroll}`}
        position="fixed"
        display={log ? "" : "none"}
      >
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Stack direction="row" spacing={2} sx={{ ml: "2vw" }}>
                  <PhoneEnabledIcon color="primary" />
                  <Typography variant="subtitle2" color="primary">
                    Contactate: (+54)9 341-3409322
                  </Typography>
                  <DraftsIcon color="primary" />
                  <Typography variant="subtitle2" color="primary">
                    info@humanware.com.ar
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={2} sx={{ mr: "2vw" }}>
                  <a href="https://www.facebook.com/hrfactors/" target="_blank">
                    <FacebookIcon color="primary" className={`${styles.iconFacebook}`} />
                  </a>
                  <a href="https://www.instagram.com/hwapplications/" target="_blank">
                    <InstagramIcon color="primary" className={`${styles.iconInstagram}`} />
                  </a>
                  <a href="https://www.youtube.com/channel/UCzzwRyquqFBwSCSU8Gwww_A" target="_blank">
                    <YouTubeIcon color="primary" className={`${styles.iconYoutube}`} />
                  </a>
                  <a href="https://www.linkedin.com/company/humanware-applications/ " target="_blank">
                    <LinkedInIcon color="primary" className={`${styles.iconLinkedin}`} />
                  </a>
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <AppBar
        id="appBar2"
        className={`${styles.navBar} ${log ? "" : styles.fondoScroll}`}
        position="fixed"
        sx={{ mt: "64px" }}
      >
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <Grid container spacing={2} columns={16}>
              <Grid item xs={5} direction="row" justifyContent="center" alignItems="center">
                <Link to="/">
                  <img className={`${styles.tamanio}`} src={logo} alt="Logo" />
                </Link>
              </Grid>
              <Grid item sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                  <Link to="quienes-somos" className={`${styles.link}`}>
                    <Button sx={{ my: 2, color: "white", display: "block", mr: 2 }}>Quienes Somos</Button>
                  </Link>
                  <Box sx={{ flexGrow: 0 }}>
                    <Button onClick={handleOpenUserMenu} sx={{ my: 2, color: "white", display: "block", mr: 2 }}>
                      Aplicaciones
                    </Button>
                    <Menu
                      color="black"
                      sx={{ mt: "45px", overflow: "hidden" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <Link to="/aplicaciones" className={`${styles.link}`}>
                        <Button
                          onClick={handleCloseUserMenu}
                          className={`${styles.linkMenu}`}
                          sx={{
                            my: 2,
                            color: "black",
                            display: "block",
                            px: 3,
                            mr: 2,
                          }}
                        >
                          Aplicaciones
                        </Button>
                      </Link>
                      <Link to="/people-one-click" className={`${styles.link}`}>
                        <Button
                          onClick={handleCloseUserMenu}
                          className={`${styles.linkMenu}`}
                          sx={{
                            my: 2,
                            color: "black",
                            display: "block",
                            px: 3,
                            mr: 2,
                          }}
                        >
                          People One Click
                        </Button>
                      </Link>
                      <Link to="/qua-one-click" className={`${styles.link}`}>
                        <Button
                          onClick={handleCloseUserMenu}
                          className={`${styles.linkMenu}`}
                          sx={{
                            my: 2,
                            color: "black",
                            display: "block",
                            px: 3,
                            mr: 2,
                          }}
                        >
                          QUA One Click
                        </Button>
                      </Link>
                      <Link to="/sgr-one-click" className={`${styles.link}`}>
                        <Button
                          onClick={handleCloseUserMenu}
                          className={`${styles.linkMenu}`}
                          sx={{
                            my: 2,
                            color: "black",
                            display: "block",
                            px: 3,
                            mr: 2,
                          }}
                        >
                          SGR One Click
                        </Button>
                      </Link>
                      <Link to="/cm-one-click" className={`${styles.link}`}>
                        <Button
                          onClick={handleCloseUserMenu}
                          className={`${styles.linkMenu}`}
                          sx={{
                            my: 2,
                            color: "black",
                            display: "block",
                            px: 3,
                            mr: 2,
                          }}
                        >
                          CM One Click
                        </Button>
                      </Link>
                      <Link to="/people-one-click-educativa" className={`${styles.link}`}>
                        <Button
                          onClick={handleCloseUserMenu}
                          className={`${styles.linkMenu}`}
                          sx={{
                            my: 2,
                            color: "black",
                            display: "block",
                            px: 3,
                            mr: 2,
                          }}
                        >
                          People One Click Educativa
                        </Button>
                      </Link>
                      <Button
                        onClick={handleCloseUserMenu}
                        className={`${styles.linkMenu}`}
                        sx={{
                          my: 2,
                          color: "black",
                          display: "block",
                          px: 3,
                          mr: 2,
                        }}
                      >
                        Portal One Click
                      </Button>
                      <Link to="/aval-one-click" className={`${styles.link}`}>
                        <Button
                          onClick={handleCloseUserMenu}
                          className={`${styles.linkMenu}`}
                          sx={{
                            my: 2,
                            color: "black",
                            display: "block",
                            px: 3,
                            mr: 2,
                          }}
                        >
                          AVAL One Click
                        </Button>
                      </Link>
                      <Link to="/dynamics-CRM-365" className={`${styles.link}`}>
                        <Button
                          onClick={handleCloseUserMenu}
                          className={`${styles.linkMenu}`}
                          sx={{
                            my: 2,
                            color: "black",
                            display: "block",
                            px: 3,
                            mr: 2,
                          }}
                        >
                          Dynamics CRM 365
                        </Button>
                      </Link>
                    </Menu>
                  </Box>
                  <Link to="capacitaciones" className={`${styles.link}`}>
                    <Button onClick={handleCloseUserMenu} sx={{ my: 2, color: "white", display: "block", mr: 2 }}>
                      Capacitaciones
                    </Button>
                  </Link>
                  <Link to="contacto" className={`${styles.link}`}>
                    <Button onClick={handleCloseUserMenu} sx={{ my: 2, color: "white", display: "block", mr: 2 }}>
                      Contacto
                    </Button>
                  </Link>
                  <Button
                    onClick={() => {
                      handleCloseUserMenu();
                      setOpen(true);
                    }}
                    sx={{ my: 2, color: "white", display: "block", mr: 2 }}
                  >
                    Pago
                  </Button>
                  <a
                    className={`${styles.link}`}
                    href="https://soporte.humanware.com.ar/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button onClick={handleCloseUserMenu} sx={{ my: 2, color: "white", display: "block", mr: 2 }}>
                      Clientes
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="primary"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <Link to="quienes-somos" className={`${styles.link}`}>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    Quienes Somos
                  </Button>
                </Link>
                <Link to="/aplicaciones" className={`${styles.link}`}>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    Aplicaciones
                  </Button>
                </Link>
                <Link to="/people-one-click" className={`${styles.link}`}>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    People One Click
                  </Button>
                </Link>
                <Link to="/qua-one-click" className={`${styles.link}`}>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    QUA One Click
                  </Button>
                </Link>
                <Link to="/sgr-one-click" className={`${styles.link}`}>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    SGR One Click
                  </Button>
                </Link>
                <Link to="/cm-one-click" className={`${styles.link}`}>
                  <Button
                    onClick={handleCloseNavMenu}
                    className={`${styles.linkMenu}`}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    CM One Click
                  </Button>
                </Link>
                <Link to="/hr-one-click-educativa" className={`${styles.link}`}>
                  <Button
                    onClick={handleCloseNavMenu}
                    className={`${styles.linkMenu}`}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    People One Click Educativa
                  </Button>
                </Link>
                <Button
                  onClick={handleCloseNavMenu}
                  className={`${styles.linkMenu}`}
                  sx={{
                    my: 2,
                    color: "black",
                    display: "block",
                    px: 3,
                    mr: 2,
                  }}
                >
                  Portal One Click
                </Button>
                <Link to="/aval-one-click" className={`${styles.link}`}>
                  <Button
                    onClick={handleCloseNavMenu}
                    className={`${styles.linkMenu}`}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    AVAL One Click
                  </Button>
                </Link>
                <Link to="/dynamics-CRM-365" className={`${styles.link}`}>
                  <Button
                    onClick={handleCloseNavMenu}
                    className={`${styles.linkMenu}`}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    Dynamics CRM 365
                  </Button>
                </Link>
                <Link to="capacitaciones" className={`${styles.link}`}>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    Capacitaciones
                  </Button>
                </Link>
                <Link to="contacto" className={`${styles.link}`}>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    Contacto
                  </Button>
                </Link>
                <Button
                  onClick={() => {
                    handleCloseNavMenu();
                    setOpen(true);
                  }}
                  sx={{
                    my: 2,
                    color: "black",
                    display: "block",
                    px: 3,
                    mr: 2,
                  }}
                >
                  Pago
                </Button>
                <a
                  className={`${styles.link}`}
                  href="https://soporte.humanware.com.ar/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      px: 3,
                      mr: 2,
                    }}
                  >
                    Clientes
                  </Button>
                </a>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <ModalPayPal open={open} handleClose={handleClose} />
    </>
  );
};
export default NavBar;
