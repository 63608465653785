import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";


const theme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: '#F7F9FB',
    },
    primary: {
      main: '#ffffff',
    },
    secondary:{
      main: "#414042"
    }
  },
  typography: {
    fontFamily: "'Maven Pro', 'sans-serif'",
  },
  components: {
    MuiButton: {
      defaultProps: {
        style: {
          textTransform: "none",
          borderRadius: "0.5em",
        },
      },
    },
    MuiDivider: {
      defaultProps: {
        style: {
          color: "#414042",
          background: "#414042"
        },
      },
  },
  },
});

export const ThemeConfig = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
