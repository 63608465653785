import { Box, Grid, Typography, Button, Container } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./HROneClick.module.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Formulario from "../../components/Formulario";
import PeopleOneClick from '../../image/People One Click Logo.png';
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Helmet } from "react-helmet";
import brochure from "../../docs/People One Click -Brochure.pdf"

function HROneClick() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Aplicaciones People One Click - Humanware Applications</title>
        <meta
          name="description"
          content="Conoce las aplicaciones que tenemos para tu empresa y comenzá a optimizar tus proceso."
        />
        <meta
          name="keywords"
          content="humanware, humanware applications, aplicaciones humanware, , aplicaciones humanware applications, People One Click"
        ></meta>
      </Helmet>
      <Box sx={{ mt: 0 }} className={`${styles.fondoHROneClick}`}>
        <Typography
          variant="h6"
          color="primary"
          sx={{ pt: 35, pl: 15 }}
          className={`${styles.parrafo01}`}
        >
          <Link to="/" className={`${styles.link}`}>
            Home
            <KeyboardArrowRightIcon
              color="primary"
              className={`${styles. link}`}
            />
          </Link>
          People One Click
        </Typography>
        <Typography
          className={`${styles.parrafo02}`}
          variant="h4"
          color="primary"
          sx={{ pt: 2, pl: 15, fontWeight: 600 }}
        >
          <img
            src={PeopleOneClick}
            className={` ${styles.logoHR}`}
            alt="People One Click"
          />
        </Typography>
        <a
          className={`btn ${styles.buttonBrochure}`}
          href={brochure}
          target="_blank"
          rel="noopener noreferrer"
          >
            Ver Brochure
        </a>
        <Container
          className={`${styles.botonDemo}`}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            mb: 5,
          }}
        >
          <Button variant="text" onClick={() => window.scroll(0, 1250)}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={`${styles.botonText}`}
            >
              Solicite una demo <br />
              <KeyboardDoubleArrowDownIcon
                color="primary"
                className={`${styles.botonText}`}
              />
            </Typography>
          </Button>
        </Container>
      </Box>
      <Box className={`${styles.fondoGestiondeCapital}`}>
        <Grid
          container
          spacing={0}
          columns={16}
          sx={{ pt: 0 }}
          className={`${styles.container}`}
        >
          <Grid
            className={`${styles.containerItem}`}
            item
            xs={8}
            sx={{
              pt: 0,
              pb: 2,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{
                px: 8,
                pt: 3,
                pb: 2,
                textAlign: "start",
                fontWeight: 600,
              }}
            >
              Gestión de Capital Humano
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start" }}
            >
              Es un sistema focalizado en la Gestión de Capital Humano que
              brinda a las áreas de RRHH todos los módulos necesarios para la
              digitalización del área, contemplando las mejores prácticas
              modernas de Gestión del Talento. Estos módulos a su vez pueden ser
              personalizados a las políticas y procedimientos de la empresa.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              People One Click es un Employee Relationship Management que utiliza
              Microsoft Dynamics XRM para sistematizar todos los procesos
              operacionales, de Atención a clientes internos/externos y de
              Personal en particular.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              People One Click se integra nativamente con las aplicaciones de Office
              365 (Word, Excel, Outlook, SharePoint, Yammer, Skype for Business)
              y Power BI para el armado de Tableros e Informes de Control.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              Esta estrategia tecnológica brinda los beneficios de Flexibilidad,
              Integración, Seguridad y Modernidad.
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{ pt: 0 }}
            className={`${styles.imagen1}`}
          ></Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          columns={16}
          sx={{ pt: 0, mb: 10 }}
          className={`${styles.container} ${styles.containerFondo}`}
        >
          <Grid
            item
            xs={8}
            sx={{ pt: 0 }}
            className={`${styles.imagen2}`}
          ></Grid>
          <Grid
            className={`${styles.businessContainer} ${styles.containerItem}`}
            item
            xs={8}
            sx={{
              pt: 0,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{
                px: 8,
                pt: 3,
                pb: 2,
                textAlign: "start",
                fontWeight: 600,
              }}
            >
              Plataforma de desarrollo rápida: MS xRM
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start" }}
            >
              Hoy en día se habla de “ The Business Platform”, la cual permite
              diseñar e implementar aplicaciones especificas por industria, como
              en este caso People One Click como solución Cross Industry.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              xRM en su versión Dynamics 365 tiene integraciones nativas con
              Office 365 (Microsoft SharePoint, Word, Excel, Outlook) para
              implementar estrategias de digitalización del negocio con todos
              sus procesos
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              Los mayores esfuerzos están focalizados en el Diseño y
              Personalización de los circuitos con activa participación de
              usuarios clave y analistas de procesos internos o externos
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              Humanware Applications provee capacitaciones y coaching on the Job
              para la personalización de su solución HR Factors a las políticas
              de la empresa
            </Typography>
          </Grid>
        </Grid>
        <Formulario />
      </Box>
    </>
  );
}

export default HROneClick;
