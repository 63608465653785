import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import './CircleComponent.css';

const CircleComponent = ({ name, imageUrl, esRect, descripcion }) => {
  const [hover, setHover] = useState(false);

  return (
    <div>
      {esRect ? (
        <Tooltip title={descripcion}>
          <div
            className="circle-wrapper"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <span className={`forma-name ${hover ? 'hover' : ''}`}>{name}</span>
            <div
              className={`forma ${hover ? 'hover' : ''} rectangulo`}
              style={{ backgroundImage: `url(${imageUrl})` }}
            />
          </div>
        </Tooltip>
      ) : (
        <Tooltip title={descripcion}>
          <div
            className="circle-wrapper"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <div
              className={`forma ${hover ? 'hover' : ''} circulo`}
              style={{ backgroundImage: `url(${imageUrl})` }}
            />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default CircleComponent;
