import { Controller, useFormState } from "react-hook-form";

const CustomTextField = ({
  name,
  label,
  Component,
  rules,
  helperText,
  type,
  readOnly = false,
  rows = 1,
  multiline = false,
  req,
  endAdornment,
  ...restProps
}) => {
  const formState = useFormState();

  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#3F819F",
      },
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#3F819F",
    },
  };

  return (
    <Controller
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Component
          margin="normal"
          type={type}
          multiline={multiline}
          rows={rows}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          ref={ref}
          error={Boolean(formState.errors && formState.errors[name])}
          helperText={formState.errors[name] ? helperText : null}
          label={label}
          // variant="outlined"
          fullWidth
          required={req}
          {...restProps}
          InputProps={{
            readOnly: readOnly,
            endAdornment,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          sx={style}
        />
      )}
      rules={rules}
    />
  );
};

export default CustomTextField;
