import React, { useEffect } from "react";
import styles from "./AvalOneClick.module.css";
import { Box, Grid, Typography, Container, Button } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "react-router-dom";
import Formulario from "../../components/Formulario";
import portalOC from "../../image/AvalBlanco.png";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Helmet } from "react-helmet";
import brochure from "../../docs/AVAL One Click -Brochure.pdf"
import Mapa from "../../components/Mapa";

function AvalOneClick() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Aplicaciones Aval One Click - Humanware Applications</title>
        <meta
          name="description"
          content="Conoce las aplicaciones que tenemos para tu empresa y comenzá a optimizar tus proceso."
        />
        <meta
          name="keywords"
          content="humanware, humanware applications, aplicaciones humanware, , aplicaciones humanware applications, Aval One Click"
        ></meta>
      </Helmet>
      <Box sx={{ mt: 0 }} className={`${styles.fondoAvalOneClick}`}>
        <Typography
          variant="h6"
          color="primary"
          sx={{ pt: 35, pl: 15 }}
          className={`${styles.parrafo01}`}
        >
          <Link to="/" className={`${styles.link}`}>
            Home
            <KeyboardArrowRightIcon
              color="primary"
              className={`${styles.link}`}
            />
          </Link>
          Aval One Click
        </Typography>
        <Typography
          className={`${styles.parrafo02}`}
          variant="h4"
          color="primary"
          sx={{ pt: 2, pl: 15, fontWeight: 600 }}
        >
          <img
            src={portalOC}
            className={` ${styles.logoportalOC}`}
            alt="Logo Aval OC"
          />
        </Typography>
        <a
          className={`btn ${styles.buttonBrochure}`}
          href={brochure}
          target="_blank"
          rel="noopener noreferrer"
          >
            Ver Brochure
        </a>
        <Container
          className={`${styles.botonDemo}`}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            mb: 5,
          }}
        >
          <Button variant="text" onClick={() => window.scroll(0, 1090)}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={`${styles.botonText}`}
            >
              Solicite una demo <br />
              <KeyboardDoubleArrowDownIcon
                color="primary"
                className={`${styles.botonText}`}
              />
            </Typography>
          </Button>
        </Container>
      </Box>
      <Box className={`${styles.fondoGestiondeCapital}`}>
        <Mapa />
        <Grid container spacing={0} columns={16} sx={{ pt: 0 }}>
          <Grid
            className={`${styles.containerItem}`}
            item
            xs={8}
            sx={{
              pt: 0,
              pb: 2,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{
                px: 8,
                pt: 3,
                pb: 2,
                textAlign: "start",
                fontWeight: 600,
              }}
            >
              Gestión Integral de Garantías, en un click
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start" }}
            >
              La Suite de Aval One Click permite gestionar todo el circuito de otorgamiento de garantías,
              desde el alta de la Empresa y la Solicitud de Aval, hasta el vencimiento del mismo.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              La gestión integral de los Avales, desde su Solicitud hasta el vencimiento.
              Permite llevar el siguimiento de todos los movimientos asociados al mismo
              (caídas, recuperos, cancelaciones).
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{ pt: 0 }}
            className={`${styles.imagen1}`}
          ></Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          columns={16}
          sx={{ pt: 0, mb: 5 }}
          className={`${styles.container} ${styles.containerFondo}`}
        >
          <Grid
            item
            xs={8}
            sx={{ pt: 0 }}
            className={`${styles.imagen2}`}
          ></Grid>
          <Grid
            className={`${styles.businessContainer} ${styles.containerItem}`}
            item
            xs={8}
            sx={{
              pt: 0,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{
                px: 8,
                pt: 3,
                pb: 2,
                textAlign: "start",
                fontWeight: 600,
              }}
            >
              Gestión Comercial
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start" }}
            >
              Permite la automatización integral de la Gestión Comercial, para de esta manera
              abocar a su fuerza de venta al crecimiento y fidelización de sus Clientes!.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              Además permite gestionar alertas e Indicadores Claves propias del área.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="primary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              La gestión de los fondos contempla la administración completa de todos 
              los aportes que conforman el Fondo de Garantías.
            </Typography>
          </Grid>
        </Grid>
        
        <Formulario />
      </Box>
    </>
  );
}

export default AvalOneClick;
