import axios from "axios";

const dataInicial = {
  loading: false,
  token: "",
  resultado: "",
};

const LOADING_TOKEN = "LOADING_TOKEN";
const TOKEN_EXITO = "TOKEN_EXITO";
const TOKEN_ERROR = "TOKEN_ERROR";

//Reducers
export default function tokenReducer(state = dataInicial, action) {
  switch (action.type) {
    case TOKEN_EXITO:
      return { ...state, token: action.payload };
    case TOKEN_ERROR:
      return { ...dataInicial, resultado: action.resultado };
    case LOADING_TOKEN:
      return { ...state, resultado: action.resultado };
    default:
      return { ...state };
  }
}

export const loginToken = () => async (dispatch) => {
  dispatch({
    type: LOADING_TOKEN,
    resultado: "LOADING",
  });
  try {
    const response = await axios.post(`https://hw365api.azurewebsites.net/api/Usuarios/login`, {
      email: "admin@hwapplications.com",
      password: "Hwa.2023$",
    });
    dispatch({
      type: TOKEN_EXITO,
      payload: response.data,
      resultado: "EXITO!",
    });
  } catch (error) {
    dispatch({
      type: TOKEN_ERROR,
      resultado: "ERROR",
    });
  }
};

export const RenovarToken = (token) => async (dispatch) => {
  dispatch({
    type: LOADING_TOKEN,
    resultado: "LOADING",
  });
  try {
    const response = await axios.get(`https://hw365api.azurewebsites.net/api/Usuarios/RenovarToken`, {
      headers: {
        Authorization: `Bearer ${token.token}`,
      },
    });
    dispatch({
      type: TOKEN_EXITO,
      payload: response.data,
      resultado: "EXITO!",
    });
  } catch (error) {
    dispatch({
      type: TOKEN_ERROR,
      resultado: "ERROR",
    });
  }
};
