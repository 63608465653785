import {
  Typography,
  Box,
  Container,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./Capacitaciones.module.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "react-router-dom";
import imagen from "../../image/section-capacitaciones.jpg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BusinessIcon from "@mui/icons-material/Business";
import AddIcon from "@mui/icons-material/Add";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import Formulario from "../../components/Formulario";
import { Helmet } from "react-helmet";

function Capacitaciones() {
  const [counterOn, setCounterOn] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Capacitaciones - Humanware Applications</title>
        <meta
          name="description"
          content="En Humanware Applications contamos con un centro equipado y expertos para brindar capacitaciones."
        />
        <meta
          name="keywords"
          content="Humanware, Humanware Applications, capacitaciones, capacitaciones Microsoft, Partner de Microsoft"
        ></meta>
      </Helmet>
      <Container
        sx={{ mt: 0 }}
        className={`${styles.fondoCapacitacionesPortada}`}
      >
        <Typography
          variant="h6"
          color="primary"
          sx={{ pt: 35, pl: 15 }}
          className={`${styles.parrafo01}`}
        >
          <Link to="/" className={`${styles.link}`}>
            Home
            <KeyboardArrowRightIcon
              color="primary"
              className={`${styles.link}`}
            />
          </Link>
          Capacitaciones
        </Typography>
        <Typography
          className={`${styles.parrafo02}`}
          variant="h4"
          color="primary"
          sx={{ pt: 2, pl: 15, fontWeight: 600 }}
        >
          Capacitaciones in Company
        </Typography>
      </Container>
      <Box className={`${styles.fondoCapacitaciones}`}>
        <Grid container columns={12} sx={{ pt: 0 }}>
          <Grid
            className={`${styles.containerParrafo}`}
            item
            xs={6}
            sx={{
              pt: 0,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              className={`${styles.parrafo03}`}
              variant="h4"
              color="primary"
              sx={{
                px: 11,
                pt: 10,
                pb: 5,
                textAlign: "start",
                fontWeight: 600,
              }}
            >
              Contamos con un centro equipado y expertos para brindar
              capacitaciones.
            </Typography>
            <Typography
              className={`${styles.parrafo04}`}
              variant="subtitle1"
              color="primary"
              sx={{ px: 11, textAlign: "start" }}
            >
              Humanware Applications provee como partner de Microsoft y en el
              marco de su misión de promover el conocimiento interno llevar a
              cabo servicios de instalación y configuración como así también
              capacitaciones in company a la medidas de las necesidades y
              oportunidades que se definen con el cliente
            </Typography>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              <Grid
                container
                className={`${styles.contadorContainer}`}
                columns={16}
                sx={{
                  pt: 5,
                  px: 11,
                  pb: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={9}>
                  <AccessTimeIcon color="primary" sx={{ fontSize: 60 }} />
                  <Typography variant="h4" color="primary">
                    {counterOn && <CountUp start={0} end={770} duration={4} />}
                    <AddIcon
                      color="primary"
                      sx={{ fontSize: 40, color: "#22A249" }}
                    />
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: 600 }}
                  >
                    Talleres
                    <br />
                    Realizados
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <BusinessIcon color="primary" sx={{ fontSize: 60 }} />
                  <Typography variant="h4" color="primary">
                    {counterOn && <CountUp start={0} end={2} duration={6} />}
                    <span className={`${styles.colorK}`}>K</span>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: 600 }}
                  >
                    Personas
                    <br />
                    Capacitadas
                  </Typography>
                </Grid>
              </Grid>
            </ScrollTrigger>
          </Grid>
          <Grid item xs={6} sx={{ pt: 0 }} className={`${styles.containerImg}`}>
            <img
              src={imagen}
              className={`${styles.imagenCapacitaciones}`}
              alt="imagen capacitaciones"
            />
          </Grid>
        </Grid>
        <Grid
          className={`${styles.cardContainer}`}
          container
          direction="row"
          justifyContent="space-around"
          alignItems="flex-start"
          sx={{ mb: 10, mt: 6, px: 2, pb: 5 }}
        >
          <Card
            className={`${styles.cardHover} ${styles.cardMovil}`}
            sx={{
              maxWidth: 300,
              minHeight: 500,
              maxHeight: 500,
              backgroundColor: "transparent",
              border: "1px solid #fff",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="div" color="primary">
                Office 365
              </Typography>
              <Typography
                sx={{ px: 5, pt: 3, pb: 1 }}
                color="primary"
                align="center"
              >
                Está enfocado a incrementar la productividad reduciendo tiempos
                innecesarios y mejorando la comunicación con los niveles de
                protección que cada empresa y área requiere, además Office 365
                para empresa cuenta con diferentes planes que permiten a las
                empresas elegir las aplicaciones y funcionalidades que más se
                adecúen a los retos de negocio.
              </Typography>
            </CardContent>
          </Card>
          <Card
            className={`${styles.cardHover} ${styles.cardMovil}`}
            sx={{
              maxWidth: 300,
              minHeight: 500,
              maxHeight: 500,
              backgroundColor: "transparent",
              border: "1px solid #fff",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="div" color="primary">
                SharePoint
              </Typography>
              <Typography
                sx={{ mb: 1.5, px: 5, py: 3 }}
                color="primary"
                align="center"
              >
                SharePoint es el software de colaboración más poderoso de
                Microsoft, el cual integra poderosas funcionalidades que le
                brindaran a su fuerza laboral nuevas formas de compartir y
                colaborar para mejorar la productividad en la organización.
                Ademas en su nueva versión en la nube tiene integración nativa
                con Office 365 y con redes sociales.
              </Typography>
            </CardContent>
          </Card>
          <Card
            className={`${styles.cardHover} ${styles.cardMovil}`}
            sx={{
              maxWidth: 300,
              minHeight: 500,
              maxHeight: 500,
              backgroundColor: "transparent",
              border: "1px solid #fff",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="div" color="primary">
                Msft. Dynamics 365
              </Typography>
              <Typography
                sx={{ mb: 1.5, px: 5, py: 3 }}
                color="primary"
                align="center"
              >
                Microsoft Dynamics 365 es una plataforma de desarrollo rapido
                integrada con office 365. Las Compañía están tratando de
                transformarse digitalmente para lograr cuatro objetivos
                fundamentales: atraer a los clientes, capacitar a los empleados,
                optimizar los procesos y transformar los productos. Dynamics 365
                permite alcanzar todos estos desafíos
              </Typography>
            </CardContent>
          </Card>
          <Card
            className={`${styles.cardHover}`}
            sx={{
              maxWidth: 300,
              minHeight: 500,
              maxHeight: 500,
              backgroundColor: "transparent",
              border: "1px solid #fff",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="div" color="primary">
                Power BI
              </Typography>
              <Typography
                sx={{ mb: 1.5, px: 5, py: 3 }}
                color="primary"
                align="center"
              >
                Power BI es un servicio de análisis de negocio basado en la nube
                que proporciona una vista única de los datos más críticos de su
                negocio. Supervise el estado de su empresa mediante un panel
                activo, cree informes interactivos enriquecidos con Power BI
                Desktop y obtenga acceso a los datos sobre la marcha con
                aplicaciones Power BI Mobile nativas. Es fácil, rápido y
                gratuito.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Formulario />
      </Box>
    </>
  );
}

export default Capacitaciones;
