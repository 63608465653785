import React, { useEffect, useState } from "react";
import Carrusel from "../../components/Carrusel";
import Accordion from "@mui/material/Accordion";
import { Link } from "react-router-dom";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import WorkIcon from "@mui/icons-material/Work";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import imgHR_Factors from "../../image/HR-Factors-01-1 (1).png";
import imgQUAGestion from "../../image/QUA-Gestion-Resumen-Ejecutivo-01-1.png";
import QUA_Gestion1g from "../../image/QUA-Gestion1-01.png";
import CheckIcon from "@mui/icons-material/Check";
import Divider from "@mui/material/Divider";
import styles from "./Home.module.css";
import "animate.css";
import mapaContacto from "../../image/contacto.png";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  IconButton,
} from "@mui/material";
import MultiCarrusel from "../../components/MultiCarrusel";
import Formulario from "../../components/Formulario";
import { Helmet } from "react-helmet";
import { LinkRounded } from "@mui/icons-material";

function Home() {
  const [expanded, setExpanded] = useState(false);
  const [counterOn, setCounterOn] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [animate2, setAnimate2] = useState(false);
  const [animate3, setAnimate3] = useState(false);
  const [animate4, setAnimate4] = useState(false);
  const [animate5, setAnimate5] = useState(false);
  const [animate6, setAnimate6] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setAnimate(false);
    setAnimate2(false);
    setAnimate3(false);
    setAnimate4(false);
    setAnimate5(false);
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Helmet>
        <title>Humanware Applications – People before strategy</title>
        <meta
          name="description"
          content="Humanware Applications es una firma profesional, Partner de Microsoft, 
          que provee soluciones corporativas para la transformación digital de los Procesos."
        />
        <meta
          name="keywords"
          content="Humanware, Humanware Applications, SGR, Microsoft, Partner de Microsoft"
        ></meta>
      </Helmet>
      <Box>
        <Carrusel />
        <Box sx={{ mx: 1 }}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              sx={{ color: "#1786A0", fontWeight: "bold", mt: 10 }}
            >
              People Before Strategy
            </Typography>
            <ScrollTrigger onEnter={() => setAnimate(true)}>
              <Typography
                className={`${styles.parrafo01Movil} ${
                  animate
                    ? "animate__animated animate__fadeInUp animate-duration: 3000ms"
                    : ""
                }`}
                variant="h4"
                color="initial"
                align="center"
                sx={{ fontWeight: "bold", p: 8, color: "#414042" }}
              >
                Proveemos soluciones corporativas para la
                <br />
                transformación digital de los Procesos.
              </Typography>
            </ScrollTrigger>
            <ScrollTrigger onEnter={() => setAnimate2(true)}>
              <Typography
                className={`${styles.parrafo02Movil} ${
                  animate2 ? "animate__animated animate__fadeInUp" : ""
                }`}
                variant="h6"
                color="initial"
                align="center"
                sx={{ color: "#7A7C7F", fontWeight: "fontWeightMedium" }}
              >
                En Humanware Applications creemos que la Competitividad
                Organizacional
                <br />
                se apoya en la transformación digital con soluciones y
                plataformas
                <br />
                de desarrollo rápido.
              </Typography>
            </ScrollTrigger>
            <hr sx={{ color: "#1786A0" }} />
          </Grid>
          <ScrollTrigger onEnter={() => setAnimate3(true)}>
            <Grid
              container
              className={`${styles.cardContainer1}`}
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              sx={{ mb: 20, mt: 10, px: 2 }}
            >
              <Card
                className={`${styles.cardMovil} ${styles.cardMovil1} ${
                  animate3 ? "animate__animated animate__fadeInUp" : ""
                }`}
                sx={{
                  maxWidth: 375,
                  maxHeight: 405,
                  backgroundColor: "#016191",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ p: 4 }} color="#fff" gutterBottom>
                    <LibraryBooksIcon fontSize="large" />
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    color="primary"
                    className={`${styles.parrafoCard01}`}
                  >
                    Aplicaciones
                  </Typography>
                  <Typography
                    className={`${styles.parrafoCard02}`}
                    sx={{ px: 5, pt: 3, pb: 1 }}
                    color="#fff"
                    align="center"
                  >
                    Aplicaciones Core para el negocio, como SGR One Click
                    gestión Integral de SGRs, People One Click gestión de Recursos
                    Humanos, QUA One Click control de calidad, CM One Click
                    gestion construcción, Portal One Clcik portales webs.
                  </Typography>
                  <CardActions>
                    <Link
                      to="/aplicaciones"
                      size="medium"
                      className={`${styles.link}`}
                    >
                      <Typography
                        className={`${styles.buttonHoverCard}`}
                        variant="subtitle2"
                        color="#757575"
                        sx={{ fontWeight: 700 }}
                      >
                        Ver Más <KeyboardArrowRightIcon />
                      </Typography>
                    </Link>
                  </CardActions>
                </CardContent>
              </Card>
              <Card
                className={`${styles.cardMovil} ${styles.cardMovil2} ${
                  animate3 ? "animate__animated animate__fadeInUp" : ""
                }`}
                sx={{
                  maxWidth: 375,
                  minHeight: 415,
                  backgroundColor: "#0186A0",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ p: 4 }} color="#fff" gutterBottom>
                    <QuestionAnswerIcon fontSize="large" />
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    color="primary"
                    className={`${styles.parrafoCard01}`}
                  >
                    Corporate Performance Management
                  </Typography>
                  <Typography
                    className={`${styles.parrafoCard02}`}
                    sx={{ mb: 1.5, px: 5, py: 3 }}
                    color="#fff"
                    align="center"
                  >
                    Corporate Performance Management con Kepion Planning y Power
                    BI como soluciones para gestión estratégica y táctica del
                    negocio.
                  </Typography>
                </CardContent>
              </Card>
              <Card
                className={`${styles.cardMovil} ${styles.cardMovil3} ${
                  animate3 ? "animate__animated animate__fadeInUp" : ""
                }`}
                sx={{
                  maxWidth: 375,
                  maxHeight: 405,
                  backgroundColor: "#016191",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ p: 4 }} color="#fff" gutterBottom>
                    <LibraryAddCheckIcon fontSize="large" />
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    color="primary"
                    className={`${styles.parrafoCard01}`}
                  >
                    Capacitaciones
                  </Typography>
                  <Typography
                    className={`${styles.parrafoCard02}`}
                    sx={{ mb: 1.5, px: 5, py: 3 }}
                    color="#fff"
                    align="center"
                  >
                    Capacitación y desarrollo de competencias internas para la
                    efectiva transferencia de know how en la tecnología y
                    nuestras soluciones.
                  </Typography>
                  <CardActions>
                    <Link
                      to="/capacitaciones"
                      size="medium"
                      className={`${styles.link}`}
                    >
                      <Typography
                        className={`${styles.buttonHoverCard}`}
                        variant="subtitle2"
                        color="#757575"
                        sx={{ fontWeight: 700 }}
                      >
                        Ver Más <KeyboardArrowRightIcon />
                      </Typography>
                    </Link>
                  </CardActions>
                </CardContent>
              </Card>
            </Grid>
          </ScrollTrigger>
          <Grid
            container
            spacing={2}
            columns={16}
            className={`${styles.suiteHRMovil}`}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              background: "#F8F8F8",
            }}
          >
            <Grid item xs={8} className={`${styles.gridImgMovil}`}>
              <ScrollTrigger onEnter={() => setAnimate4(true)}>
                <img
                  className={`${styles.imgHRFactors} ${styles.Img01} ${
                    animate4 ? "animate__animated animate__pulse" : ""
                  }`}
                  src={imgHR_Factors}
                  alt="People One Click"
                />
              </ScrollTrigger>
            </Grid>
            <Grid
              item
              xs={8}
              sx={{ px: 10 }}
              className={`${styles.parrafo03Movil}`}
            >
              <Typography
                className={`${styles.parrafo04Movil}`}
                variant="h4"
                color="#414042"
                sx={{ fontWeight: 700, p: 5 }}
              >
                Suite People One Click
              </Typography>
              <Typography
                variant="h6"
                color="#7A7C7F"
                sx={{ px: 5, fontWeight: 600 }}
              >
                Microsoft Dynamics CRM
              </Typography>
              <Typography
                variant="subtitle1"
                color="#7A7C7F"
                sx={{ px: 5 }}
                className={`${styles.parrafo05Movil}`}
              >
                Descubre cómo nuestras soluciones de Gestión Financiera y de People, 
                desarrolladas en Dynamics 365, pueden transformar tu negocio.
                Con una plataforma robusta y flexible, optimizamos la contabilidad, presupuestación y análisis financiero, 
                mientras mejoramos el reclutamiento, desempeño y desarrollo del personal. Impulsa la eficiencia 
                y el crecimiento de tu empresa con tecnología integrada que se adapta a tus necesidades. 
              </Typography>
              <Link
                to="/hr-one-click"
                className={`${styles.buttonHover} ${styles.link}`}
              >
                <Button
                  variant="contained"
                  sx={{
                    m: 4,
                    px: 3,
                    py: 1,
                    background: "#0186A0",
                    color: "#fff",
                  }}
                >
                  Ver Más <KeyboardArrowRightIcon />
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            columns={16}
            className={`${styles.suiteHRMovil} ${styles.suiteHRMovil02}`}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              background: "#fff",
              marginTop: 10,
            }}
          >
            <Grid
              item
              xs={8}
              sx={{ px: 10 }}
              className={`${styles.parrafo03Movil}`}
            >
              <Typography
                className={`${styles.parrafo04Movil}`}
                variant="h4"
                color="#414042"
                sx={{ fontWeight: 700, p: 5 }}
              >
                QUA One Click
              </Typography>
              <Typography
                variant="h6"
                color="#7A7C7F"
                sx={{ px: 5, fontWeight: 500 }}
              >
                Gestión Integral de Calidad
              </Typography>
              <Typography variant="subtitle1" color="#7A7C7F" sx={{ px: 5 }}>
                Es un sistema focalizado en la Gestión Integral de la Calidad
                que brinda a las Organizaciones todos los módulos necesarios
                para la gestión y digitalización integral de documentación y
                procesos, contemplando las mejores prácticas modernas de
                implementación. Estos módulos a su vez pueden ser personalizados
                a las políticas y procedimientos de la empresa.
              </Typography>
              <Typography
                variant="subtitle1"
                color="#7A7C7F"
                sx={{ px: 5, mt: 3, fontWeight: 600 }}
              >
                <CheckIcon color="success" /> Intranet RRHH – Portal del
                Empleado – Carpeta Digital
              </Typography>
              <Link
                to="/qua-one-click"
                className={`${styles.buttonHover} ${styles.link}`}
              >
                <Button
                  variant="contained"
                  sx={{
                    m: 4,
                    px: 3,
                    py: 1,
                    background: "#0186A0",
                    color: "#fff",
                  }}
                >
                  Ver Más <KeyboardArrowRightIcon />
                </Button>
              </Link>
            </Grid>
            <Grid
              item
              xs={8}
              className={`${styles.imgHRFactors} ${styles.gridImgMovil}`}
            >
              <ScrollTrigger onEnter={() => setAnimate5(true)}>
                <img
                  className={`${styles.imgHRFactors} ${styles.gridImgMovil} ${
                    styles.Img01
                  } ${animate5 ? "animate__animated animate__pulse" : ""}`}
                  src={imgQUAGestion}
                  alt="QUA-One Click"
                />
              </ScrollTrigger>
            </Grid>
          </Grid>
          <Grid
            className={`${styles.suiteHRMovil} ${styles.suiteHRMovil02}`}
            container
            spacing={2}
            columns={16}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              background: "#F8F8F8",
              marginTop: 10,
              marginBottom: 3,
            }}
          >
            <Grid item xs={8}>
              <ScrollTrigger onEnter={() => setAnimate6(true)}>
                <img
                  className={`${styles.imgHRFactors} ${styles.displayNone} ${
                    styles.Img01
                  } ${animate6 ? "animate__animated animate__pulse" : ""}`}
                  src={QUA_Gestion1g}
                  alt="HR One Click"
                />
              </ScrollTrigger>
            </Grid>
            <Grid
              item
              xs={8}
              sx={{ px: 10 }}
              className={`${styles.parrafo03Movil}`}
            >
              <Typography
                className={`${styles.parrafo04Movil}`}
                variant="h4"
                color="#414042"
                sx={{ fontWeight: 700, p: 5 }}
              >
                SGR One Click
              </Typography>
              <Typography
                variant="h6"
                color="#7A7C7F"
                sx={{ px: 5, fontWeight: 500 }}
              >
                SGR One click con Dynamics 365
              </Typography>
              <Typography variant="subtitle1" color="#7A7C7F" sx={{ px: 5 }}>
                Las Sociedades de Garantías Recíprocas están teniendo un
                crecimiento significativo de volumen de operaciones y han nacido
                como una unidad de negocio de Grupos Empresarios.
              </Typography>
              <Link
                to="/sgr-one-click"
                className={`${styles.buttonHover} ${styles.link}`}
              >
                <Button
                  variant="contained"
                  sx={{
                    m: 4,
                    px: 3,
                    py: 1,
                    background: "#0186A0",
                    color: "#fff",
                  }}
                >
                  Ver Más <KeyboardArrowRightIcon />
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Grid
            className={`${styles.fondoDecisiones}`}
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <Typography
                variant="h5"
                align="center"
                color="#414042"
                sx={{ px: 5, fontWeight: 700 }}
              >
                Proporcione a su empresa todo lo que <br /> necesita para
                triunfar
              </Typography>
              <Typography
                variant="subtitle1"
                color="#7A7C7F"
                sx={{ px: 5, mt: 6 }}
                align="center"
              >
                Unifique toda su empresa, desde los datos hasta las personas,
                pasando por los
                <br />
                procesos, con aplicaciones modernas e inteligentes que se
                adaptan a las necesidades
                <br />
                cambiantes de su negocio
              </Typography>
            </Grid>

            <Grid
              container
              className={`${styles.cardContainer}`}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                mb: 20,
                mt: 10,
                px: 3,
              }}
            >
              <Card
                className={`${styles.cardMovil}`}
                sx={{ maxWidth: 375, maxHeight: 385, backgroundColor: "#fff" }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="h2"
                    color="#3E6369"
                    align="left"
                    sx={{ fontWeight: 600, px: 2 }}
                  >
                    01.
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ mb: 1, px: 2, py: 3, fontWeight: 600 }}
                    color="#414042"
                    align="left"
                  >
                    Tome mejores decisiones.
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1.5, px: 2, py: 2 }}
                    color="#414042"
                    align="left"
                  >
                    Mejore los resultados empresariales con conocimientos y
                    recomendaciones basados en los datos.
                  </Typography>
                  <Divider
                    color="secondary"
                    sx={{ marginLeft: "2vh", width: "30%" }}
                    className={`${styles.br}`}
                  />
                </CardContent>
              </Card>
              <Card
                className={`${styles.cardMovil}`}
                sx={{ maxWidth: 375, maxHeight: 385, backgroundColor: "#fff" }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="h2"
                    color="#3E6369"
                    align="left"
                    sx={{ fontWeight: 600, px: 2 }}
                  >
                    02.
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ mb: 1, px: 2, py: 3, fontWeight: 600 }}
                    color="#414042"
                    align="left"
                  >
                    Forje relaciones con los clientes.
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1.5, px: 2, py: 2 }}
                    color="#414042"
                    align="left"
                  >
                    Facilite una interacción más fructífera con una vista
                    completa del cliente.
                  </Typography>
                  <Divider
                    color="secondary"
                    sx={{ marginLeft: "2vh", width: "30%" }}
                    className={`${styles.br}`}
                  />
                </CardContent>
              </Card>
              <Card
                className={`${styles.cardMovil}`}
                sx={{ maxWidth: 375, maxHeight: 385, backgroundColor: "#fff" }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="h2"
                    color="#3E6369"
                    align="left"
                    sx={{ fontWeight: 600, px: 2 }}
                  >
                    03.
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ mb: 1, px: 2, py: 3, fontWeight: 600 }}
                    color="#414042"
                    align="left"
                  >
                    Trabaje de manera más eficaz.
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1.5, px: 2, py: 2 }}
                    color="#414042"
                    align="left"
                  >
                    Automatice y optimice las tareas diarias con flujos de
                    trabajo de IA.
                  </Typography>
                  <Divider
                    color="darkgrey"
                    sx={{ marginLeft: "2vh", width: "30%" }}
                    className={`${styles.br}`}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={`${styles.fondoServicios}`}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: 600 }}
              color="primary"
              className={`${styles.serviciosMovil}`}
            >
              Servicios que Perduran en el Tiempo
            </Typography>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Card
                  sx={{ minWidth: 275, backgroundColor: "transparent" }}
                  className={`${styles.borderBottomMovil}`}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h3" color="#fff">
                      <WorkIcon fontSize="large" />
                    </Typography>
                    <Typography
                      variant="h2"
                      sx={{ mb: 1.5, mt: 2, color: "green" }}
                      color="primary"
                    >
                      {counterOn && (
                        <CountUp start={0} end={100} duration={4} />
                      )}
                      %
                    </Typography>
                    <Typography
                      sx={{ mb: 1.5, fontWeight: 600 }}
                      color="primary"
                    >
                      Profesionales Certificados
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{ minWidth: 275, backgroundColor: "transparent" }}
                  className={`${styles.borderBottomMovil}`}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h3" color="#fff">
                      <QueryStatsIcon fontSize="large" />
                    </Typography>
                    <Typography
                      variant="h2"
                      sx={{ mb: 1.5, mt: 2, color: "green" }}
                      color="primary"
                    >
                      {counterOn && (
                        <CountUp start={0} end={100} duration={4} />
                      )}
                      +
                    </Typography>
                    <Typography
                      sx={{ mb: 1.5, fontWeight: 600 }}
                      color="primary"
                    >
                      Proyectos Realizados
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{ minWidth: 275, backgroundColor: "transparent" }}
                  className={`${styles.borderBottomMovil} ${styles.paddinBottomMovil}`}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h3" color="#fff">
                      <WorkIcon fontSize="large" />
                    </Typography>
                    <Typography
                      variant="h2"
                      sx={{ mb: 1.5, mt: 2, color: "green" }}
                      color="primary"
                    >
                      {counterOn && <CountUp start={0} end={99} duration={4} />}
                      %
                    </Typography>
                    <Typography
                      sx={{ mb: 1.5, fontWeight: 600 }}
                      color="primary"
                    >
                      Profesionales Certificados
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </ScrollTrigger>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <Typography
              variant="h5"
              align="center"
              color="#414042"
              sx={{ px: 5, fontWeight: 700 }}
            >
              Contamos con un soporte 365
            </Typography>
            <Typography
              variant="subtitle1"
              color="#7A7C7F"
              sx={{ px: 5, mt: 6, fontWeight: 500 }}
              align="center"
            >
              Contamos con una estructura capaz de darle un servicio eficiente,
              rápido
              <br />y a su medida.
            </Typography>
          </Grid>
          <Grid
            container
            spacing={2}
            columns={16}
            sx={{ mt: 10 }}
            className={`${styles.suiteHRMovil}`}
          >
            <Grid item xs={8} sx={{ p: 5 }} className={`${styles.gridMovil}`}>
              <Typography
                variant="h6"
                color="#414042"
                sx={{ px: 5, mb: 4 }}
                className={`${styles.parrafo05Movil}`}
              >
                Unifique toda su empresa.
              </Typography>
              <Typography variant="subtitle1" color="#7A7C7F" sx={{ px: 5 }}>
                Desde los datos hasta las personas, pasando por los procesos,
                con aplicaciones modernas e inteligentes que se adaptan a las
                necesidades cambiantes de su negocio.
              </Typography>
              <Typography
                variant="subtitle1"
                color="#414042"
                sx={{ px: 5, py: 3 }}
              >
                <CheckIcon color="success" /> Soporte 365 días del año
              </Typography>
              <Typography
                variant="subtitle1"
                color="#414042"
                sx={{ px: 5, pb: 3 }}
              >
                <CheckIcon color="success" /> Eleve tickets con tan solo 1 Clik
                para que podamos resolver todas sus consultas
              </Typography>
              <Typography
                variant="subtitle1"
                color="#414042"
                sx={{ px: 5, pb: 3 }}
              >
                <CheckIcon color="success" /> Contamos con asesores del mejor
                nivel certificados por Microsoft
              </Typography>
            </Grid>
            <Grid item xs={8} className={`${styles.accordionContainer}`}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                sx={{ backgroundColor: "#EDF9ED", mb: 3 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#414042" }} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography color="#414042" sx={{ py: 2, fontWeight: 600 }}>
                    Unifique su empresa
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="#414042">
                    Conectando personas, procesos y datos a través de Dynamics
                    365, Office 365, LinkedIn y Azure.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
                sx={{ backgroundColor: "#EDF9ED", mb: 3 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#414042" }} />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography color="#414042" sx={{ py: 2, fontWeight: 600 }}>
                    Se ajusta a sus necesidades
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="#414042">
                    Ya que permite integrar las aplicaciones con los sistemas
                    existentes o utilizar Microsoft Power Platform. para
                    personalizarlas y mejorarlas.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
                sx={{ backgroundColor: "#EDF9ED", mb: 3 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#414042" }} />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography color="#414042" sx={{ py: 2, fontWeight: 600 }}>
                    Adopte un enfoque moderno
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="#414042">
                    Aproveche la inteligencia artificial, la realidad mixta, las
                    redes sociales y las funcionalidades móviles para agilizar
                    la innovación empresarial.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <Grid
            className={`${styles.fondoConfianNosotros} ${styles.suiteHRMovil}`}
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              px: 4,
            }}
          >
            <Typography
              className={`${styles.parrafo06Movil}`}
              variant="h5"
              color="#414042"
              sx={{ fontWeight: "bold", pt: 10, pb: 3 }}
            >
              Clientes que confían en nosotros
            </Typography>
            <MultiCarrusel />
          </Grid>
          <Grid
            className={`${styles.containerPrefooter}`}
            spacing={2}
            columns={16}
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              px: 4,
              mt: 4,
              mb: 5,
            }}
          >
            <Grid item xs={9} className={`${styles.accordionContainer}`}>
              <img
                src={mapaContacto}
                className={`${styles.mapaContacto}`}
                alt="Mapa Contacto"
              />
            </Grid>
            <Grid
              className={`${styles.formularioContainer}`}
              item
              xs={7}
              sx={{
                backgroundColor: "#fff",
                minHeight: "100%",
                backgroundColor: "#fff",
              }}
            >
              <Formulario />
            </Grid>
          </Grid>
        </Box>
        <Box className={`${styles.botonFlotante}`}>
          <IconButton
            onClick={() => {
              window.scroll(0, 0);
            }}
            component="label"
            sx={{
              position: "fixed",
              bottom: "12%",
              right: "5%",
              backgroundColor: "#fff",
            }}
          >
            <KeyboardArrowUpIcon
              color="secondary"
              fontSize="large"
              className={`${styles.botonFlotanteIcon}`}
            />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}

export default Home;
